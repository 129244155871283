// ImageModalExample.tsx
import React from 'react';
import ModalImage from 'react-modal-image';

// Define the props interface
interface ImageModalExampleProps {
  smallImage: string;
  largeImage: string;
  altText: string;
}

const ImageModalExample: React.FC<ImageModalExampleProps> = ({ smallImage, largeImage, altText }) => {
  return ( 
     
      <ModalImage
        small={smallImage}
        large={largeImage}
        alt={altText}
      /> 
  );
};

export default ImageModalExample;
