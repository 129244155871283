import { Card, Col, Container, Row } from 'reactstrap'
import TodoSideBar from './TodoSideBar'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { Apps, ToDoHeading } from '../../../utils/Constant'
import TodoBody from './TodoBody'
import ProjectListHeaderSubmenu from '../Project/ProjectList/ProjectListHeaderSubmenu'
import SubMenu from '../SubMenu/SubMenu'

const TodoContainer = () => {
  return (
    <>
      <Breadcrumbs mainTitle={ToDoHeading} parent={Apps} />
      <SubMenu/>
      <Container fluid className="email-wrap bookmark-wrap todo-wrap">
        <Row>
        <Col md="12" className="project-list">
          <Card className='my-md-1'> 
            <Row className='my-md-1'>
              <ProjectListHeaderSubmenu/> 
            </Row>
          </Card>
          </Col>
          <TodoSideBar />
          <TodoBody />
        </Row>
      </Container>
    </>
  )
}

export default TodoContainer