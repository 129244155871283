
import { BrandTools, ManageUploadedContentTrash, Search } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ManageContents } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import MailPagination from '../../../../Component/Application/Email/LetterBox/EmailRightSide/InboxContent/MailPagination'
import { LI, UL } from '../../../../AbstractElements'
import InboxEmailContent from '../../../../Component/Application/Email/LetterBox/EmailRightSide/InboxContent/InboxEmailContent'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import RightDropDown from '../../../../Component/Application/Email/LetterBox/EmailRightSide/EmailHeader/RightDropDown'
import { setSearchWord } from '../../../../ReduxToolkit/Reducers/LetterBoxSlice'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import ManageSubMenu from '../ManageSubMenu/ManageSubMenu'



const TrashPhotos = () => {
    const dispatch = useAppDispatch()
    const {interviewEmail} = useAppSelector((state)=>state.letterBox)
    const {searchWord} = useAppSelector((state)=>state.letterBox)
    const {page, inboxEmail } = useAppSelector((state) => state.letterBox);
    
   
    return (
      <div className='page-body'>
        <Breadcrumbs mainTitle={`${ManageUploadedContentTrash}`} parent={BrandTools} /> 
        <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageSubMenu />
                    </CardHeader>
                    </Container>
        <Container fluid>
          <Row className='px-2'>
            <Col sm="12">
              <Card>   
                <CardBody>
                  <div className="email-wrap email-main-wrapper">
                    <Row>
                      {/* <EmailSidebar navId={navId} setNavId={setNavId} /> */}
                      <Col xxl="12" xl="12" className="box-col-12 px-2">
                        <div className="email-right-aside">
                          <Card className={`email-body email-list ${interviewEmail ? "hide" : "show"}`}>
                            <div className="mail-header-wrapper">
                              <div className="mail-header">
                                {/* <div className="form-check form-check-inline m-0">
                                  <Input className="form-check-input checkbox-primary" id="emailCheckbox1" type="checkbox" defaultValue="option1"/>
                                  <Label className="form-check-label" for="emailCheckbox1" />
                                  <EmailNavTab />
                                </div> */}
                              </div>
                              <div className="mail-body">
                                <div className="mail-search d-flex-align-items-center">
                                  <Input type="text" placeholder={Search} value={searchWord} onChange={(e)=>dispatch(setSearchWord(e.target.value))}/>
                                  <i className="fa fa-search" />
                                </div>
                                <div className="light-square">
                                  <i className="fa fa-refresh" />
                                </div>
                                <div className="light-square">
                                  <i className="fa fa-trash" />
                                </div>
                                <RightDropDown />
                              </div>
                            </div>
                            <div className="mail-body-wrapper px-3">
                              <UL className="simple-list">
                                {inboxEmail.map((data,i)=>(
                                  <LI className={`inbox-data ${page ? i < 7 ? "hidden" : "" : i < 7 ? "" : "hidden" }`}  key={i}>
                                    <InboxEmailContent data={data} i={i}/> 
                                  </LI>
                                ))}
                              </UL>
                            </div>
                            <MailPagination />
                            {/* <TabContent id="email-pills-tabContent" activeTab={navId}>
                              
                              <SentContent />
                              <StarredContent />
                              <DraftContent />
                              <TrashContent />
                              <WorkContent />
                              <PrivateContent />
                              <SupportContent />
                              <AddLabelModal />
                            </TabContent> */}
                          </Card>
                          {/* <InterviewMail /> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}

export default TrashPhotos