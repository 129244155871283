import { Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  BrandSupportTools,
  AddSpecialParameter,
} from "../../../../utils/Constant";
import SpecialParametersMain from "../../../../Component/Tools/BrandSupportTools/SpecialParameters/SpecialParametersMain";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { StudyMaterialAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions"; 
import { Link } from "react-router-dom";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";

const AddSpecialParameters = () => {
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AddSpecialParameter} parent={BrandSupportTools} />
      <SubMenu/>
     <div className='pb-2 px-3'> 
                    <StudyMaterialSubMenu />
                  </div>
      <Container className="card p-4" fluid>
        <SpecialParametersMain/>
      </Container>
    </div>
  );
};

export default AddSpecialParameters;
