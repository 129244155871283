import React, { useState } from "react";
import { CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, Image, SVG } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { ErrorMessage, Field, Formik } from "formik";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import CustomSelect from "../../CommonInput/CustomSelect";
import { Keywords, Next, Previous } from "../../../utils/Constant";
import TagsInput from "../../CommonInput/TagsInput";
import CommonButton from "../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";
import ImageModalExample from "./ImageModal";
interface MyComponentProps {
    popup: boolean; 
    heading: boolean;
  }
const AddDetails: React.FC<MyComponentProps> =  ({popup,heading }) => {
    const handleSubmit = () => {};
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];

    return (
        <div className= {`  position-relative mb-3 ${popup ?'p-4' : ""}`} >
            {heading ?  <CardHeader className="ps-0 pt-2">
                <H3>{'Add Details (step 2)'}</H3>
              </CardHeader> : ""}
            <Row className="gap-3 mt-3">
                <Col md="5">
                    <div>
                        <ImageModalExample
                            smallImage={dynamicImage("ecommerce/01.jpg")}
                            largeImage={dynamicImage("ecommerce/01.jpg")}
                            altText="User"
                        />
                    </div>
                </Col>
                <Col md="6">
                    <div className="position-relative h-100">
                        <Formik
                            initialValues={createPostInitialValue}
                            onSubmit={handleSubmit}
                            validationSchema={createPostSchema}>
                            {({ errors, values, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label check>
                                                    {`Category *`}:
                                                </Label>
                                                <FormGroup floating>
                                                    <Field
                                                        className={`custom-select  `}
                                                        name="CategorySelect"
                                                        options={cityOptions}
                                                        component={CustomSelect}
                                                        placeholder="Category"
                                                        isMulti={true}
                                                    />
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col sm="12" className="mb-3">
                                            <Label check>
                                                Description{" "}
                                                <span className="txt-danger">
                                                    *
                                                </span>
                                            </Label>
                                            <Field
                                                name="description"
                                                as="textarea"
                                                maxLength="140"
                                                placeholder="Description"
                                                className={`form-control 
                                    ${
                                        submitErrors &&
                                        `${
                                            errors.description
                                                ? "is-invalid"
                                                : "is-valid"
                                        }`
                                    }
                                `}
                                            />
                                            <ErrorMessage
                                                name="description"
                                                component="span"
                                                className="invalid-feedback"
                                            />
                                        </Col>
                                        <Col sm="12">
                                            <FormGroup>
                                                <div className=" col-form-Label">
                                                    <Label check>
                                                        {Keywords}:
                                                    </Label>
                                                    <TagsInput
                                                        name="keywords"
                                                        placeholder="Add keywords"
                                                        className="w-100"
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AddDetails;
