import { useEffect } from "react";
import CustomSelect from "./CustomSelect";
import { Field } from "formik";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks"; 
import { useGetCountryDataQuery } from "../../ReduxToolkit/Reducers/Country/countryApiSlice";
import { setCounteries } from "../../ReduxToolkit/Reducers/commonInputSlice";
import Loading from "../Loading/Loading";

function Country(props: any) {
  const { countries } = useAppSelector((state: any) => state.common);
  const { data: fetchCountries, isLoading } = useGetCountryDataQuery(); 
  const { name, className, isMulti, placeholder } = props; 
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fetchCountries?.data) {
      // Map the fetched countries to your desired structure
      let filter_country = fetchCountries.data.map((item: any) => {
        return { label: item.country_name, value: item.id };
      });

      // Add the clearable option
      filter_country = [...filter_country];

      // Dispatch the countries to the Redux store
      dispatch(setCounteries(filter_country));
    }
  }, [fetchCountries?.data, dispatch]);

  if (isLoading) return  <div>Data not found!</div>;

  if (!countries || countries.length === 0) return <div>Data not found!</div>;

  return (
    <Field
      className={`custom-select ${className}`}
      name={name}
      options={countries}
      component={CustomSelect}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
}

export default Country;
