import {useState} from 'react'
import { ManageCoupons } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { AddonCategoryColumnData,  supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { CouponTableAction, ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'

const ManageMyCoupons = () => {
    const toggleDelete = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}

    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={ManageCoupons} parent={`Manage Store`} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card > 
              <CardBody className='py-2'>
                <>              
                <div>
                {CouponTableAction.map((action, index)=>{
                  return(
                  <>
                    <Link to={`${process.env.PUBLIC_URL}/${action.path}`} key={index}><span className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                  </>)
                })}
                </div>
                {/* </Col></Row> */}
                <div className="table-responsive pt-2">
                  <DataTable className='custom-scrollbar' columns={AddonCategoryColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ManageMyCoupons