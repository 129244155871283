// import { useState } from "react";
// import { Card, CardBody, Col, Modal, Row } from "reactstrap";
// import { Btn, H4, H5 } from "../../../AbstractElements";
// import { Carousel } from "react-responsive-carousel";
// import CommonButton from "../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";

// export interface MediaModalInterface {
//     value: boolean;
//     setOpenModal: (value: boolean) => void;
//     dataId: undefined | number;
// }

// const MediaModal = ({value , setOpenModal , dataId}:MediaModalInterface) => {
//     const [open, setOpen] = useState(value);
//     const onCloseModal = () => {
//         setOpen(false);
//         setOpenModal(false);
//     };
//     return (
//         <Modal wrapClassName='product-box' className="modal-dialog-scrollable"  fade centered size="xl" isOpen={open} toggle={onCloseModal}>
//             <div className="modal-header" >
//                 <H4>{`Media Name`}</H4> 
//                 <Btn className="py-0" onClick={onCloseModal} close></Btn>
//             </div>
//             {/* <div className="modal-header"><h5 className="modal-title">Modal title</h5><button className="close" type="button">×</button></div> */}
//             <div className='modal-body bg-light'>
//                 <Row className="product-box">
//                     <Col lg="4" sm="12">
//                         <Card>
//                             <CardBody>
//                             <Carousel
//                                 className="owl-carousel owl-theme"
//                                 showStatus={false}
//                                 showIndicators={false}
//                                 showArrows={true}
//                                 swipeable={true}
//                                 autoPlay={true}
//                                 infiniteLoop={true}
//                                 showThumbs={true}
//                             >
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/01.jpg" alt="" />
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/02.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/03.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/04.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/05.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/06.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/07.jpg" alt=""/>
//                                 </div>
//                                 <div className="item">
//                                 <img src="../assets/images/ecommerce/08.jpg" alt=""/>
//                                 </div>
//                             </Carousel>
//                             </CardBody>
//                         </Card>
//                     </Col>
//                     <Col>
                    
//                     </Col>
//                 </Row>
//             </div>
//             <div className="modal-footer">
//             <CommonButton step={true}/>
//             </div>
//         </Modal>
//     )
// }

// export default MediaModal

import { useState } from "react";
import { Card, CardBody, Col, Modal, Row } from "reactstrap";
import { Btn, H4, SVG } from "../../../AbstractElements";
import { Carousel } from "react-responsive-carousel";
import CommonButton from "../../Application/Ecommerce/AddProduct/ProductBody/CommonButton/CommonButton";

export interface MediaModalInterface {
    value: boolean;
    setOpenModal: (value: boolean) => void;
    dataId: number;  // Assuming it will be a number
    items: { id: number; title: string }[];  // Pass the list of items
}

const MediaModal = ({ value, setOpenModal, dataId, items }: MediaModalInterface) => {
    const [open, setOpen] = useState(value);
    const [currentId, setCurrentId] = useState(dataId);

    const onCloseModal = () => {
        setOpen(false);
        setOpenModal(false);
    };

    const handleNext = () => {
        if (currentId < items.length - 1) {
            setCurrentId(currentId + 1);
        }
    };

    const handlePrevious = () => {
        if (currentId > 0) {
            setCurrentId(currentId - 1);
        }
    };

    return (
        <Modal wrapClassName='product-box' className="modal-dialog-scrollable" fade centered size="xl" isOpen={open} toggle={onCloseModal}>
            <div className="modal-header">
                <H4>{items[currentId].title}</H4>
                <Btn className="py-0" onClick={onCloseModal} close></Btn>
            </div>
            <div className='modal-body bg-light'>
                <Row className="product-box">
                    <Col lg="4" sm="12">
                        <Card>
                            <CardBody>
                                <Carousel
                                    className="owl-carousel owl-theme"
                                    showStatus={false}
                                    showIndicators={false}
                                    showArrows={true}
                                    swipeable={true}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showThumbs={true}
                                >
                                    <div className="item">
                                        <img src="../assets/images/ecommerce/01.jpg" alt="" />
                                    </div>
                                    <div className="item">
                                        <img src="../assets/images/ecommerce/02.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer ">
                <div className="next_previous position-relative pt-0" > 
                <Btn onClick={handlePrevious} className="border" disabled={currentId === 0}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Previous           <SVG iconId=" back-arrow" />
                    </div>
                </Btn>
                <Btn onClick={handleNext} className="border" disabled={currentId === items.length - 1}>
                    <div className="d-flex align-items-center gap-sm-2 gap-1">
                    Next             <SVG iconId="front-arrow"/>
                    </div>
                </Btn>
                </div>
                {/* <CommonButton step={true} /> */}
            </div>
        </Modal>
    );
};

export default MediaModal;
