import { Card, CardHeader, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageBranches, DeliveryFeesTitle } from "../../../../utils/Constant"; 
import DeliveryFeesMain from "../../../../Component/Tools/ManageOnlineStore/DeliveryFees/DeliveryFeesMain";
import ManageOnlineSubMenu from "../ManageOnlineSubMenu/ManageOnlineSubMenu";
import DeliverySubMenu from "../DeliverySubMenu/DeliverySubMenu";

const AddDeliveryFees = () => {

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={DeliveryFeesTitle} parent={ManageBranches} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container> 
      <Container  fluid>
      <DeliverySubMenu/>
        <Card className=" p-4 mt-2">
         <DeliveryFeesMain/>
         </Card>
      </Container>
    </div>
  );
};

export default AddDeliveryFees;
