import { Card, Col, Container, Input, Label, Row } from 'reactstrap'
import UserBanner from '../../../Component/App/User/UserBanner'
import { Btn, H1, H3, H4, Image, LI, Progressbar, UL } from '../../../AbstractElements'
import {  AddResume,  Cancel,  DeleteData, Home, MyResumeses, Save } from '../../../utils/Constant'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useEffect, useState } from 'react'
import { dynamicImage } from '../../../Service'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../CommonElements/Breadcrumbs/Breadcrumbs'
import Popup from '../../../Component/MasterPopup/Popup'
import { TableHeadColumn } from './MyRegistrationPlan'

export const resumesData = [
  {
    id:1,
    name:"James",
    image:"user/5.jpg",
    email:"start@gmail.com",
    skill: 100,
    skillColor: "info",
  },
  {
    id:2,
    name:"Kelvin",
    image:"user/5.jpg",
    email:"raft@gmail.com",
    skill: 90,
    skillColor: "danger",
  }
];

export interface ImageDataProp{
  image:string;
  title:string
}

const ImageData: React.FC<ImageDataProp> = ({ image, title }) => {
  return (
    <div className="d-flex">
      <Image
        className="rounded-circle img-30 me-3"
        src={dynamicImage(`${image}`)}
        alt="Generic placeholder image"
      />
      <div className="flex-grow-1 align-self-center">
        <div>{title}</div>
      </div>
    </div>
  );
};

export interface SupportDataType {
  id: number;
  image: string;
  name:string;
  email:string;
  skill:number;
  skillColor:string;
  date:string;
}

export interface ActionButtonProps{
  action:number;
}


const ActionButtons: React.FC<ActionButtonProps> = ({action}) => {
  return(
      <div className="d-flex justify-content-end">
          <Btn size='sm' color='primary' className='mx-2 px-2'>
            <i className="fa fa-pencil" />
          </Btn>
          <Btn size='sm' color='success' className='mx-2 px-2'>
            <i className="fa fa-link" /> 
          </Btn>
          <Btn size='sm' color='danger' className='mx-2 px-2'>
            <i className="fa fa-trash" /> 
          </Btn>
      </div>
  );
}


export const resumesColumn: TableColumn<SupportDataType>[] = [
  {
    name: "Image",
    cell: (row) => <ImageData image={row.image} title={row.name} />,
    sortable: true,
    center: false,
  },
  {
    name:"Name",
    selector: (row) => row["name"],
    sortable: true,
    center: false,
  },
  {
    name:"Email",
    selector: (row) => row["email"],
    sortable: true,
    center: false,
  },
  {
    name: "Skill",
    cell: (row) => <SkillsData value={row.skill} skillColor={row.skillColor} />,
    sortable: true,
    center: true,
  },
  {
    name:<TableHeadColumn title={`Actions`}/>,
    cell: (row) =><ActionButtons action={1}/>,
    sortable: true,
    right: true,
  },
];

export interface SkillsDataProp{
  value: number;
  skillColor:string
}

const SkillsData: React.FC<SkillsDataProp> = ({ value, skillColor }) => {
  return (
    <div className="progress-showcase" style={{ width: "86px" }}>
      <Progressbar value={value} color={skillColor} style={{ height: "8px" }} />
    </div>
  );
};

const MyResumes = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [data, setData] = useState(resumesData);
  const [openModal, setOpenModal] = useState(false);
  const [workAvailabilityStatus , setWorkAvailabilityStatus] = useState<string>("");

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map((r: SupportDataType) => r.name)}?`)) {
      setToggleDelete(!toggleDelete);
      setData(data.filter((item) => (selectedRows.filter((elem: SupportDataType) => elem.id === item.id).length > 0 ? false : true)));
      setSelectedRows([]);
    }
  };
  const handleRowSelected =() =>{}
  useEffect(()=>{
    
  });


  return (
    <div className='page-body'>
        <Breadcrumbs mainTitle={`My Resumes`} parent={Home} />
        <UserBanner/>
        <Container fluid>
          <Card>
            <Row className='px-3 py-1 d-flex justify-content-center'>
              <Col xl="12" className='py-2'>
                <Btn className='me-1 btn-dark' onClick={()=>setOpenModal(!openModal)}>Work Availability</Btn>
                <Link to={`${process.env.PUBLIC_URL}/account-settings/my-resumes/add-new`}><Btn type="button" className='btn-dark'>{AddResume}</Btn></Link>
                <Popup title={`Work Availability`} openModalToggle={()=>setOpenModal(!openModal)} isOpen={openModal} size={`lg`} backdrop={true} isClose={false}>
                  <div className='p-4'>
                    <H3>{`Are you searching for a job ?`}</H3>
                    <div className="form-check form-check-inline radio radio-primary  mt-2 mx-4">
                      <Input id={`radioinline_yes`} type="radio" name={`radio_yes`}  onClick={()=>setWorkAvailabilityStatus("Yes")}/>
                      <Label className="mb-0" for={`radioinline_yes`} check>YES</Label>
                    </div>
                    <div className="form-check form-check-inline radio radio-primary  mt-2">
                      <Input id={`radioinline_no`} type="radio" name={`radio_yes`}  onClick={()=>setWorkAvailabilityStatus("No")}/>
                      <Label className="mb-0" for={`radioinline_no`} check>NO</Label>
                    </div>
                  </div>
                  { workAvailabilityStatus == "Yes" ? (
                    <div className='mx-4 my-2'>
                      <h3>{`Display a Public Notice on My Media Center about my Work Availability ?`}</h3>
                    <UL>
                      <LI>
                        <div className="form-check form-check-inline radio radio-primary  mt-2 mx-4">
                          <Input id={`radioinline_status`} type="radio" name={`radio_available`}  onClick={()=>setWorkAvailabilityStatus("Yes")}/>
                          <Label className="mb-0" for={`radioinline_status`} check>Anyone</Label>
                        </div>
                      </LI>
                      <LI>
                        <div className="form-check form-check-inline radio radio-primary  mt-2 mx-4">
                          <Input id={`radioinline_statuss`} type="radio" name={`radio_available`}  onClick={()=>setWorkAvailabilityStatus("Yes")}/>
                          <Label className="mb-0" for={`radioinline_statuss`} check>Followers Only</Label>
                        </div>
                      </LI>
                      <LI>
                        <div className="form-check form-check-inline radio radio-primary  mt-2 mx-4">
                          <Input id={`radioinline_statust`} type="radio" name={`radio_available`}  onClick={()=>setWorkAvailabilityStatus("Yes")}/>
                          <Label className="mb-0" for={`radioinline_statust`} check>None</Label>
                        </div>
                      </LI>
                    </UL>
                  </div>
                  ):("")}

                  <div className='mx-4 my-2 mb-4'>
                    <Btn color='secondary' className='me-2' >{Save}</Btn>
                    <Btn color='primary' onClick={()=>setOpenModal(!openModal)}>{Cancel}</Btn>
                  </div>
                </Popup>
              </Col>
              <Col xl="12" className='d-flex justify-content-between ms-4 ps-3 pe-2 pb-4'><H1>{MyResumeses}</H1></Col>
            
              <Col md="12" className='ms-2'>
                <Card className="custom-project-card">
                  {/* <CardHeaderCommon title={``} tagClass="card-title mb-0" /> */}
                  {/* <Table responsive className="custom-scrollbar theme-scrollbar add-project card-table table-vcenter text-nowrap">
                    <thead>
                      <tr>
                        <th>{SRNO}</th>
                        <th>{DefaultPublicResume}</th>
                        <th>{Name}</th>
                        <th>{Type}</th>
                        <th>{JobHeading}</th>
                        <th>{Languages}</th>
                        <th>{Subcategories}</th>
                        <th>{Actions}</th>
                      </tr>
                    </thead>
                  </Table> */}
                  <div className="table-responsive">
                  {selectedRows.length !== 0 && (
                    <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                      <H4 className="text-muted m-0">{DeleteData}</H4> 
                      <Btn color="danger" onClick={handleDelete}>{`Test`}</Btn>
                    </div>
                  )}
                  <DataTable className='custom-scrollbar' columns={resumesColumn} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                </Card>
              </Col>
            </Row>
        </Card>
        </Container>
    </div>
  )
}

export default MyResumes;