import { CardHeader, Col, Form, Input, Label, Row } from "reactstrap";
import { ChooseYourCurrency, InitialCost, ProductStocks, SellingPrice } from "../../../../../../utils/Constant";
import TypesOfProduct from "./TypesOfProduct";
import { useDispatch } from "react-redux";
import { setFormValue } from "../../../../../../ReduxToolkit/Reducers/AddProductSlice";
import BranchSetupPrice from "./BranchSetupPrice";
import { scheduleFormInitialValue, scheduleFormValidationSchema, SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { Formik } from "formik";
import { useState } from "react";
import CategoryTable from "./CategoryTable";
import { H3 } from "../../../../../../AbstractElements";

const ProductFour = () => {
  const dispatch = useDispatch()
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const handleSubmit = (values: SchedulesFormValidationProp) => {
      console.log(values);
      setSubmitError(false);
  };
  
  return (
    <>
    <CardHeader className="ps-0 pt-2">
                <H3>{'Branch and Price Setup (step 4)'}</H3>
              </CardHeader>
    <div className="sidebar-body">
      <Formik
                        initialValues={scheduleFormInitialValue}
                        onSubmit={handleSubmit}
                        validationSchema={scheduleFormValidationSchema}>
                        {({ errors }) => (
      <BranchSetupPrice />
                        )}
                        </Formik> 
    </div>
    </>
  );
};

export default ProductFour;
