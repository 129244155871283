import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import MobileColData from "./MobileColData";
import Symbol from "./Symbol";

const DynamicPlanData = (props: any) => {
  const { mobileView } = useAppSelector((state) => state.layout);
  const planData = props.dData.data.dynamic;
  let pkg = "tetset";

  const { countries } = useAppSelector((state) => state.common);
  const selectedCountry = countries.filter((item: any) => {
    return item.value === props.countryId;
  });

  const countryLabel = selectedCountry.map((item : any ) => item?.label ); 

  return (
    <>
      {planData.map((item: any, index: number) => (
        <div className="border-bottom-0 auto text-nowrap px-4 p-2" key={index}>
          <Table bordered hover>
            <tbody>
              <tr>
                <td>
                  <b>{item.plan_name}</b>
                </td>
              </tr>
              <tr style={{ height: '225px' }}>
                <td>
                  {Object.keys(item.pricings).map((m_item, index) => (
                    <p key={index}>{item.pricings[m_item]}</p>
                  ))}
                  {props?.isButton && (
                    <Link
                      to={`${process.env.PUBLIC_URL}/register-account/${item.plan_id}/${countryLabel.toString()}/${item.registration_type}`}
                    >
                      <Btn
                        className={`btn py-1 my-1  ${
                          pkg === "Premium"
                            ? "bg-light text-primary"
                            : "btn-primary text-white"
                        }`}
                      >{`Register`}</Btn>
                    </Link>
                  )}
                </td>
              </tr>
              {item.details.map((d_item: any, d_index: number) => (
                <React.Fragment key={d_index}>
                  <tr>
                    <td>
                      <p>
                        <b>{d_item.heading}</b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {Object.keys(d_item.sub_headings).map(
                        (sub_item, i_index) => (
                          <React.Fragment key={i_index}>
                            <>
                              {(d_index == 0 && i_index == 0) ||
                              (d_index == 1 && i_index == 3) ||
                              (d_index == 1 && i_index == 4) ||
                              (d_index == 1 && i_index == 5) ||
                              (d_index == 2 && i_index == 0) ||
                              (d_index == 2 && i_index == 2) ||
                              (d_index == 2 && i_index == 7) ||
                              (d_index == 3 && i_index == 0) ||
                              (d_index == 3 && i_index == 1) ||
                              (d_index == 5 && i_index == 1) ? (
                                <p>
                                  <Symbol
                                    value={d_item.sub_headings[sub_item]}
                                  />
                                </p>
                              ) : (
                                <p>
                                  <Symbol
                                    value={d_item.sub_headings[sub_item]}
                                  />
                                </p>
                              )}
                            </>
                          </React.Fragment>
                        )
                      )}
                    </td>
                  </tr>
                  </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </>
  );
};

export default DynamicPlanData;
