import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H6, LI } from "../../../../AbstractElements";
import { InfoDrop } from "../../../../utils/Constant"; 
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import InfoBox from "./InfoBox";
import { setActiveIcon } from "../../../../ReduxToolkit/Reducers/ActiveHeaderRightIcon";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";

const Info = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const dropdownRef = useRef(null); // Reference for the dropdown
  // const dispatch = useAppDispatch();
  // const activeIcon = useAppSelector((state) => state.activeIcon.activeIcon);

  // const handleClick = () => {
  //   dispatch(setActiveIcon('info'));  
  //   setIsOpen(prevState => !prevState);
  // };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsOpen(false); // Close dropdown when clicking outside
  //     dispatch(setActiveIcon(''));
  //   }
  // };

  // const handleVisibilityChange = () => {
  //   if (document.hidden) {
  //     setIsOpen(false); // Close dropdown when the tab is hidden
  //     dispatch(setActiveIcon(''));
  //   }
  // };

  // useEffect(() => {
  //   if (isOpen) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     document.addEventListener('visibilitychange', handleVisibilityChange);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [isOpen]);
  const dropdownRef = useRef(null);
  const dispatch = useAppDispatch();
  const activeIcon = useAppSelector((state) => state.activeIcon.activeIcon);

  const toggleDropdown = () => {
    // Toggle logic for dropdown visibility
    if (activeIcon === 'info') {
      dispatch(setActiveIcon('')); // Hide dropdown
    } else {
      dispatch(setActiveIcon('info')); // Show dropdown
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (activeIcon === 'info') {
        dispatch(setActiveIcon('')); // Hide dropdown when clicking outside
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeIcon]);

  return (
    <li className={`position-relative ${activeIcon === 'info' ? 'actvie-righticon' : ''}`} onClick={toggleDropdown}>
      <div
        className="position-relative"
        ref={dropdownRef}
      >
        <div className="message">
          <FontAwesomeIcon icon={faCircleInfo} />
        </div>
        {activeIcon === 'info' ? (
          <div className="info message-dropdown position-absolute bg-white">
            <H6 className="f-18 mb-0 dropdown-title py-2">Info Drop</H6>
            <InfoBox />
          </div>
        ) : null}
      </div>
    </li>
  );
};

export default Info;