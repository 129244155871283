import { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { H2 } from "../../AbstractElements";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Logo } from "../Images";
import { Formik } from "formik";
import { useResetPasswordMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import {
  ResetPasswordFormProps,
  resetPasswordFormInitialValues,
  resetPasswordFormSchema,
} from "./FormSchema";
import { toast } from "react-toastify";
import ResetForm from "./ResetForm";
import { AfterLoginRedirectUrl, LoginPageRedirectUrl } from "../../utils/Constant";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import Loading from "../Loading/Loading";


function ResetPassword() {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const { token }: any = useParams();

  const [resetPasswordApiCall] = useResetPasswordMutation();
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || LoginPageRedirectUrl;

  useEffect(() => {
    if(userInfo){
      navigate(AfterLoginRedirectUrl);
    }
  },[userInfo, navigate])

  const handleSubmit = async (values: ResetPasswordFormProps) => {
    try {
      if (values.password !== values.confirmPassword) {
        toast.error("Password is not matched.");
        return;
      } else {
        const response = await resetPasswordApiCall({
          password: values.password,
          password_confirmation: values.confirmPassword,
          token: token,
        }).unwrap();
        const { success, message }: any = response;
        if (success === true) { 
          toast.success(message, { position: "bottom-right" });

          navigate(redirect);
        } else {
          toast.error(message);
        }
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong.");
    }
    setSubmitError(false);
  };

  if(userInfo) return <Loading/>;

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card  login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={"/"}>
                  <Logo width={500} className="w-25" />
                </Link>
              </div>
              <div className="login-main">
                <H2 className="text-center mb-4">Reset Password</H2>
                <Container className="p-3">
                  <Formik
                    initialValues={resetPasswordFormInitialValues}
                    onSubmit={handleSubmit}
                    validationSchema={resetPasswordFormSchema}
                  >
                    {({ errors }) => (
                      <ResetForm
                        submitErrors={submitErrors}
                        setSubmitError={setSubmitError}
                        errors={errors}
                      />
                    )}
                  </Formik>
                </Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ResetPassword;
