import { Col, Input, Label } from 'reactstrap';
import { Btn, FeatherIcons, H4, LI, P, UL } from '../../../../AbstractElements';
import { CalendarFilter, DraggableEvents, Href, RemoveAfterDrop } from '../../../../utils/Constant';
import { calenderInitialData } from '../../../../Data/Application/Calendar/Calendar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const adminDropdown = [
  {
    name: "Location",
    children: [  'Online',  'City'] 
  },
  {
    name: "Open For",
    children: [ 'Any Person', 'Followers Only', 'Invited Users only' ] 
  }
];

const CalendarEvents = () => {
  const [openCalender, setOpenCalender] = useState(false); 
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Toggle the accordion item
  const handleAccordionToggle = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  // Handle user selection (example function)
  const handleUserSelect = (userName) => {
    console.log(`User selected: ${userName}`);
  };

  // Handle click (example function)
  const handleClick = () => {
    // Logic for click action
  };
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const handleCheckboxChange = (label: string) => {
    setCheckedItems(prevState => {
      const isChecked = prevState.includes(label);
      const updatedItems = isChecked 
        ? prevState.filter(item => item !== label)
        : [...prevState, label];

      console.log(`Checked Items: ${updatedItems}`);
      return updatedItems;
    });
  };
  return (
    <Col xxl="2" md={2} lg={3} className="box-col-4-end">
      <div className="md-sidebar mb-3">
        <Btn tag="a" color="primary" className="md-sidebar-toggle" href={Href} onClick={() => setOpenCalender(!openCalender)}>
          {CalendarFilter}
        </Btn>
        <div className={`md-sidebar-aside job-left-aside custom-scrollbar ${openCalender ? "open" : ""}`}>
          <div id="external-events" className="calendar-default">
            <H4 className='mb-1'>{DraggableEvents}</H4>
            <div className="external-events-list">
              {calenderInitialData.events.map((event, index) => (
                <div
                  className={`fc-event       text-sm mb-1 border-0  `}
                  title={event.title}
                  key={index}
                >
                  <div className=" ">
                  <label>
                          <input
                            type="checkbox"
                            value={event.title}
                            onChange={() => handleCheckboxChange(event.title)}
                            checked={checkedItems.includes(event.title)}
                          />
                          {` ${event.title}`}
                        </label>
                  </div>
                </div>
              ))}
            </div>

            <UL className="profile_drop simple-list show_profile_drop profile-dropdown">
              {adminDropdown.map((data, index) => (
                <LI key={index} className='mb-0'>
                  <div
                    onClick={() => handleAccordionToggle(index)}
                    className="d-flex align-items-center justify-content-between fc-event   text-sm border-0"
                  >
                    <div className="d-flex align-items-center"> 
            <H4 className='mb-1'>{data.name}</H4>

                    </div>
                    <FeatherIcons className='text-white'
                      iconName={activeAccordion === index ? "ChevronDown" : "ChevronRight"}
                    />
                  </div>

                  {/* {activeAccordion === index && ( */}
                    <UL className=" pt-1">
                    {data.children.map((label, index) => (
                      <LI key={index} className="">
                        <label>
                         {label != "city" ? <input
                            type="checkbox"
                            value={label}
                            onChange={() => handleCheckboxChange(label)}
                            checked={checkedItems.includes(label)}
                          /> :""}
                          {` ${label}`}
                        </label>
                      </LI>
                    ))}
                  </UL>
                  {/* )} */}
                </LI>
              ))}
            </UL>
            <div className="dropdown">
       
      
    </div>
            {/* <P>
              <Input className="checkbox_animated" id="drop-remove" type="checkbox" />
              <Label for="drop-remove" className="m-0">{RemoveAfterDrop}</Label>
            </P> */}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CalendarEvents;
