import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { H2 } from "../../AbstractElements"; 
import LoginForm from "./LoginForm";
import { Logo } from "../Images";

const Login = () => {
  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card  login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={'/'}>
                  <Logo width={500} className="w-25" />
                </Link>
              </div>
              <div className="login-main"> 
                <H2 className="text-center mb-4">Login</H2> 
                <LoginForm />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container> 
  );
};

export default Login;
