import { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { H2 } from "../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../Images";
import { Formik } from "formik";
import { useForgotPasswordMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import {
  ForgotPasswordFormProps,
  forgotPasswordFormInitialValues,
  forgetPasswordFormSchema,
} from "./FormSchema";
import { toast } from "react-toastify";
import ForgotForm from "./ForgotForm";
import DangerMessage from "../CommonMessage/DangerMessage";
import SuccessMessge from "../CommonMessage/SuccessMessge";
import { P } from "../../AbstractElements";
import { AfterLoginRedirectUrl, AlreadhavePassword, LoginPageRedirectUrl } from "../../utils/Constant";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import Loading from "../Loading/Loading";

const ForgotPassword = () => {
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<any>("");
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [forgotPasswordApiCall] = useForgotPasswordMutation();

  const { userInfo } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if(userInfo){
      navigate(AfterLoginRedirectUrl);
    }
  },[userInfo, navigate]);
  
  const handleSubmit = async (values: ForgotPasswordFormProps) => {
    try {
      const response = await forgotPasswordApiCall({
        email: values.email,
      }).unwrap();
      const { success, message } : any = response;
      setIsSuccess(success);
      setResponseMessage(message);
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong.");
    }
    setSubmitError(false);
  };

  if(userInfo) return <Loading/>;

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card  login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={"/"}>
                  <Logo width={500} className="w-25" />
                </Link>
              </div>
              <div className="login-main">
                {responseMessage === "" ? (
                  <>
                    <H2 className="text-center mb-4">Forgot Password</H2>
                    <Container className="p-3">
                      <Formik
                        initialValues={forgotPasswordFormInitialValues}
                        onSubmit={handleSubmit}
                        validationSchema={forgetPasswordFormSchema}
                      >
                        {({ errors }) => (
                          <ForgotForm
                            submitErrors={submitErrors}
                            setSubmitError={setSubmitError}
                            errors={errors}
                          />
                        )}
                      </Formik>
                    </Container>
                  </>
                ) : isSuccess === true ? (
                  <>
                    <SuccessMessge message={responseMessage} />
                    <P className="mt-4 mb-0">
                      {AlreadhavePassword}
                      <Link className="ms-2" to={LoginPageRedirectUrl}>
                        Login
                      </Link>
                    </P>
                  </>
                ) : (
                  <>
                    <DangerMessage message={responseMessage} />
                    <P className="mt-4 mb-0">
                      {AlreadhavePassword}
                      <Link className="ms-2" to={LoginPageRedirectUrl}>
                        Login
                      </Link>
                    </P>
                  </>
                  
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
