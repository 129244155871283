import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BranchsAction } from '../../../../../Data/Tools/ManageContents/ManageContentButtons';
interface BranchSubMenuProps {
    sliced: string;
}
const BranchSubMenu : React.FC<BranchSubMenuProps> = ({ sliced }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Function to determine if the current path is the exact path or if it starts with the link's path
    const isActive = (path) => {
        return currentPath === `${process.env.PUBLIC_URL}/${path}` || 
               currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`) && !currentPath.includes(`${process.env.PUBLIC_URL}/${path}/`);
    };
    console.log('Last three items:', BranchsAction.slice(0, -4));
    return (
        <div className='d-flex flex-wrap'>
            {BranchsAction.slice(0,sliced).map((material, index) => { 
                const activeClass = isActive(material.path) ? 'bg-gray text-white' : 'bg-dark text-light';
                return (
                    <Link 
                        to={`${process.env.PUBLIC_URL}/${material.path}`} 
                        key={index}
                        className={`border rounded p-2 m-1 ps-2 pe-2 ${activeClass}`}
                    >
                        <span>
                            {material.actionName}
                        </span>
                    </Link>
                );
            })}
        </div>
    );
}

export default BranchSubMenu;
