import { Card, Container} from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import { BrandSupportTools, AddQuestion } from "../../../../utils/Constant"; 
import AddQuestionsMain from "../../../../Component/Tools/BrandSupportTools/Questions/AddQuestionsMain";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
 
const AddQuestions = () => {

  
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AddQuestion} parent={BrandSupportTools} />
      <SubMenu/>
      <Container  fluid>
      <div className='pb-2'> 
         <StudyMaterialSubMenu />
        </div>
      <Card className="p-4">
          <AddQuestionsMain addQuizMaterial={true}/>
          </Card>
      </Container>
    </div>
  );
};

export default AddQuestions;
