import { Card, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  BrandSupportTools,
  AddStudyMaterialAndLesson,
} from "../../../../utils/Constant"; 
import StudyMaterialLessonMain from "../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialLessonMain";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import { StudyMaterialAction } from "../../../../Data/Tools/BrandSupportTools/ButtonActions";
import { Link } from "react-router-dom";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";

const AddStudyMaterialLesson = () => {
  
  return (
    <div className="page-body">
      <Breadcrumbs
        mainTitle={AddStudyMaterialAndLesson}
        parent={BrandSupportTools}
      />
      <SubMenu/>
      <Container  fluid>
     <div className='pb-2'> 
                    <StudyMaterialSubMenu />
                  </div>
        <Card className="p-4">
          <StudyMaterialLessonMain/>
        </Card>
      </Container>
    </div>
  );
};

export default AddStudyMaterialLesson;
