// import { useEffect, useState } from "react";
// import { Btn, H4 } from "../../../AbstractElements";
// import { motion } from "framer-motion"; // Import motion
// import { CardBody, Col, Collapse, Form, FormGroup, Label, Modal, Row } from "reactstrap";
// import { ChevronDown, ChevronUp } from "react-feather";
// import { quizMaterialFormInitialValue, QuizMaterialFormValidationProp, quizMaterialLessonFormValidationSchema } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
// import { ErrorMessage, Field, Formik } from "formik";
// import SubCategory from "../../CommonInput/SubCategory";
// import TextCounter from "../../CommonInput/TextCounter";
// import TagsInput from "../../CommonInput/TagsInput";
// import { MarginLeft } from "../../../utils/Constant";
// import { useAppSelector } from "../../../ReduxToolkit/Hooks";

// export const clothsDetailsData = ["Special Parameters", "Quiz Materials"];

// export interface ExamModalInterface {
//     value: boolean;
//     setquizopen: (value: boolean) => void;
// }

// const questions = [
//     { id: 1, text: 'What is the capital of France?', options: ['Paris', 'Berlin', 'Madrid', 'Rome'], correct: 0 },
//     { id: 2, text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: 1 },
//     { id: 3, text: 'Who wrote "Hamlet"?', options: ['Shakespeare', 'Dickens', 'Hemingway', 'Austen'], correct: 0 },
//     { id: 4, text: 'What is the largest planet in our solar system?', options: ['Earth', 'Jupiter', 'Mars', 'Venus'], correct: 1 },
//     { id: 5, text: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Pb', 'Fe'], correct: 0 },
//     { id: 6, text: 'In which year did the Titanic sink?', options: ['1912', '1905', '1920', '1898'], correct: 0 },
//     { id: 7, text: 'What is the hardest natural substance on Earth?', options: ['Gold', 'Iron', 'Diamond', 'Platinum'], correct: 2 },
//     { id: 8, text: 'Who painted the Mona Lisa?', options: ['Da Vinci', 'Van Gogh', 'Picasso', 'Monet'], correct: 0 },
//     { id: 9, text: 'What is the capital of Japan?', options: ['Tokyo', 'Seoul', 'Beijing', 'Bangkok'], correct: 0 },
//     { id: 10, text: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correct: 2 },
//     { id: 11, text: 'Who discovered penicillin?', options: ['Fleming', 'Edison', 'Curie', 'Pasteur'], correct: 0 },
//     { id: 12, text: 'What is the largest ocean on Earth?', options: ['Atlantic', 'Indian', 'Pacific', 'Arctic'], correct: 2 },
//     { id: 13, text: 'What element does "O" represent on the periodic table?', options: ['Oxygen', 'Gold', 'Osmium', 'Oganesson'], correct: 0 },
//     { id: 14, text: 'In which continent is Egypt located?', options: ['Asia', 'Africa', 'Europe', 'South America'], correct: 1 },
//     { id: 15, text: 'What is the main ingredient in guacamole?', options: ['Tomato', 'Pepper', 'Avocado', 'Onion'], correct: 2 },
//     { id: 16, text: 'Who was the first President of the United States?', options: ['Lincoln', 'Washington', 'Jefferson', 'Adams'], correct: 1 },
//     { id: 17, text: 'What is the chemical symbol for water?', options: ['HO', 'H2O', 'O2', 'CO2'], correct: 1 },
//     { id: 18, text: 'What is the largest land animal?', options: ['Elephant', 'Giraffe', 'Rhino', 'Hippopotamus'], correct: 0 },
//     { id: 19, text: 'Which planet is known as the Red Planet?', options: ['Venus', 'Mars', 'Jupiter', 'Saturn'], correct: 1 },
//     { id: 20, text: 'What is the capital of Canada?', options: ['Toronto', 'Ottawa', 'Vancouver', 'Montreal'], correct: 1 },
//     { id: 21, text: 'Which gas do plants primarily use for photosynthesis?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 2 },
//     { id: 22, text: 'Who is known as the "Father of Computers"?', options: ['Turing', 'Babbage', 'Jobs', 'Gates'], correct: 1 },
//     { id: 23, text: 'What is the smallest country in the world by land area?', options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'], correct: 0 },
//     { id: 24, text: 'What is the currency of Japan?', options: ['Yuan', 'Yen', 'Won', 'Ringgit'], correct: 1 },
//     { id: 25, text: 'Which planet is closest to the Sun?', options: ['Venus', 'Earth', 'Mercury', 'Mars'], correct: 2 },
//     { id: 26, text: 'What is the freezing point of water in Celsius?', options: ['0°C', '32°C', '100°C', '-10°C'], correct: 0 },
//     { id: 27, text: 'What is the largest species of shark?', options: ['Great White', 'Hammerhead', 'Whale Shark', 'Tiger Shark'], correct: 2 },
//     { id: 28, text: 'Who was the 16th President of the United States?', options: ['Lincoln', 'Grant', 'Roosevelt', 'Jackson'], correct: 0 },
//     { id: 29, text: 'What is the most abundant gas in Earth’s atmosphere?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 1 },
//     { id: 30, text: 'What is the capital of Australia?', options: ['Sydney', 'Melbourne', 'Canberra', 'Brisbane'], correct: 2 }
// ];


// const ExamModal = ({ value, setquizopen }: ExamModalInterface) => {
//     const [open, setOpen] = useState(value);
//     const [isOpen, setIsOpen] = useState(value);
//     const [currentQuestion, setCurrentQuestion] = useState(1);
//     const [timeLeft, setTimeLeft] = useState(300); // Set to 5 minutes (300 seconds)
//     const [animate, setAnimate] = useState(false);
//     const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
//     const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
//     const [answerLocked, setAnswerLocked] = useState(false); // State to lock answers
//     const quizName = "General Knowledge Quiz"; // Hardcoded quiz name

//     // Timer effect
//     useEffect(() => {
//         const timer = setInterval(() => {
//             setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
//         }, 1000);
//         return () => clearInterval(timer);
//     }, []);

//     const totalQuestions = questions.length;

//     const handleNext = () => {
//         if (currentQuestion < totalQuestions) {
//             setAnimate(true);
//             setTimeout(() => {
//                 setCurrentQuestion(currentQuestion + 1);
//                 setSelectedAnswer(null);
//                 setCorrectAnswer(null);
//                 setAnswerLocked(false); // Unlock answers for the next question
//                 setAnimate(false);
//             }, 300);
//         }
//     };

//     const handlePrevious = () => {
//         if (currentQuestion > 1) {
//             setAnimate(true);
//             setTimeout(() => {
//                 setCurrentQuestion(currentQuestion - 1);
//                 setSelectedAnswer(null);
//                 setCorrectAnswer(null);
//                 setAnswerLocked(false); // Unlock answers for the previous question
//                 setAnimate(false);
//             }, 300);
//         }
//     };

//     const handleAnswerClick = (index: number) => {
//         if (!answerLocked) {
//             const correct = questions[currentQuestion - 1].correct;
//             setSelectedAnswer(index);
//             setCorrectAnswer(correct);
//             setAnswerLocked(true); // Lock the answer after selection
//         }
//     };

//     const getButtonClass = (index: number) => {
//         if (selectedAnswer === index) {
//             return index === correctAnswer ? "btn-success" : "btn-danger";
//         }
//         return " ";
//     };

//     const currentQuestionData = questions[currentQuestion - 1];
//     // Define two colors to transition between
//     const color2 = "#ff6600"; // First color
//     const color1 = "#5cbe1c"; // Second color
//     const [submitErrors, setSubmitError] = useState<boolean>(false);

//     const handleSubmit = (values: QuizMaterialFormValidationProp) => {
//       console.log(values);
//       setSubmitError(false);
//     };
//     const { mobileView } = useAppSelector((state) => state.layout);
  
//     return (
//         <Modal
//             wrapClassName="product-box"
//             fade
//             centered
//             size="xl"
//             isOpen={open}
//             toggle={() => setquizopen(false)}
//             className="modal-dialog-scrollable">
//             <div className="modal-header" onClick={() => setquizopen(false)}>
//                 <div className="d-flex justify-content-between flex-fill">
//                 <H4>{quizName}({`Question: ${currentQuestion}/${totalQuestions}`})</H4>
//                 <div className="quiz-info">
//                     {/* <span>{`Question: ${currentQuestion}/${totalQuestions}`}</span> */}
//                     <H4>
//                         <span className="f-right me-5">{`Time Left: ${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' + timeLeft % 60 : timeLeft % 60}`}</span>
//                         </H4> 
//                 <Btn className="py-0" close></Btn>
//                 </div>
//                 </div>
//             </div>

//             <div className="modal-body bg-light">
//             <div>
//       <div onClick={() => setIsOpen(!isOpen)} className="d-flex bg-gray rounded-2 mb-2 p-2 gap-2 align-items-center justify-content-between">
//         <div className="d-flex gap-2 align-items-center">
//         <H4 className="text-white">Add Quiz Matireal</H4> 
//         </div>
//         {isOpen ? <ChevronUp /> : <ChevronDown />}
//       </div>
//       <Collapse isOpen={isOpen}>
//         <CardBody className="py-2 bg-light2">
//         <Formik
//       initialValues={quizMaterialFormInitialValue}
//       onSubmit={handleSubmit}
//       validationSchema={quizMaterialLessonFormValidationSchema}
//     >
//       {({ errors, values, setFieldValue }) => (
//           <Form className="form-wizard">
//           <Row>
//             <Col sm="4" className="mb-3">
//             <Label check>
//             <span className="txt-black fw-bold">Category:</span> General Knowledge  
//               </Label>
//               {/* <SubCategory className={`${
//                   submitErrors && `${errors.subcategory ? "is-invalid" : "is-valid"}`
//                 }`}/> */}
//             </Col>
//             <Col sm="4" className="mb-3">
//               <Label check>
//               <span className="txt-black fw-bold">Title:</span> Introduction to World History
//               </Label>
//               {/* <Field type="text" name="title" placeholder="Title" maxLength='140' className={`form-control ${
//                   submitErrors && `${errors.title ? "is-invalid" : "is-valid"}`
//                 }`}/>
//                 <TextCounter length={values?.title?.length} totalLength={'140'} /> */}
//                 <ErrorMessage
//                   name="title"
//                   component="span"
//                   className="invalid-feedback"
//                 />
//             </Col>
//             <Col sm="4" className="mb-3">
//               <Label check><span className="txt-black fw-bold"> Material Text: </span> 
//               </Label>
//               {/* <Field
//                 as="textarea"
//                 name="materialText"
//                 placeholder="Material Text"
//                 maxLength='300'
//                 className={`form-control ${
//                   submitErrors && `${errors.materialText ? "is-invalid" : "is-valid"}`
//                 }`}
//               />
//               <TextCounter length={values?.materialText?.length} totalLength={'300'} />
//               <ErrorMessage
//                   name="materialText"
//                   component="span"
//                   className="invalid-feedback"
//                 /> */}
//             </Col>
//           </Row>
//           <Row>
//             <Col sm="4" className="mb-3">
//               <Label check><span className="txt-black fw-bold">Attachment: </span> </Label>
//               {/* <FormGroup>
//                 <Field name="attachement" type="file" className='form-control' />
//                 <span className="txt-danger">
//                   Only pdf, mp3, jpg, jpeg, gif, png, webp allowed.
//                 </span>
//               </FormGroup> */}
//             </Col> 
    
//             <Col sm="4" className="mb-3">
//               <Label check><span className="txt-black fw-bold">Keywords: </span> history, world, ancient, civilizations</Label> 
//               {/* <TagsInput name="keywords" placeholder="Select keywords" setFieldValue={setFieldValue} /> */}
//             </Col>
//           </Row> 
//         </Form>
//          )}
//     </Formik>
//         </CardBody>
//       </Collapse>
//     </div>
//                 <div className="quiz-container">
//                     <motion.div
//                         key={currentQuestion}
//                         initial={{ opacity: 0, x: 100 }}
//                         animate={{ opacity: 1, x: 0, backgroundPosition: `100% 0%` }}
//                         exit={{ opacity: 0, x: -100 }}
//                         transition={{ duration: 0.5 }}
//                         className="question-content"
//                         style={{
//                             padding: "20px",
//                             borderRadius: "8px",
//                             backgroundImage: `linear-gradient(135deg, ${color1} 50%, ${color2} 50%)`,
//                             backgroundSize: "200% 100%",
//                             backgroundPosition: animate ? "100% 0%" : "0% 0%",
//                             transition: "background-position 0.5s ease-in-out"
//                         }}
//                     >
//                         <div className="d-flex flex-column  justify-content-center" style={{ marginLeft: mobileView ? "50px" : "125px" }}    >
//                             <H4 className="text-white">{currentQuestionData.text}</H4>
//                             <div className="mt-3 d-flex flex-column gap-2">
//                                 {currentQuestionData.options.map((option, index) => (
//                                     <button
//                                         key={index}
//                                         onClick={() => handleAnswerClick(index)}
//                                         className={`btn ${getButtonClass(index)} w-100 border py-2 px-3 text-white`}
//                                           // Disable buttons if answer is locked
//                                     >
//                                         {option}
//                                     </button>
//                                 ))}
//                             </div>
//                         </div>
//                     </motion.div>

//                     <footer className="sticky-footer ">
//                         <div className="next_previous">
//                         <button className="btn" onClick={handlePrevious} disabled={currentQuestion === 1}>
//                             Previous
//                         </button>
//                         <button className="btn"  onClick={handleNext} disabled={currentQuestion === totalQuestions}>
//                             Next
//                         </button>
//                         </div>
//                     </footer>
//                 </div>
//             </div>
//         </Modal>
//     );
// };

// export default ExamModal;
import { useEffect, useState } from "react";
import { Btn, H4 } from "../../../AbstractElements";
import { motion } from "framer-motion";
import { CardBody, Col, Collapse, Form, FormGroup, Label, Modal, Row } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
import { quizMaterialFormInitialValue, QuizMaterialFormValidationProp, quizMaterialLessonFormValidationSchema } from "../../../Data/Tools/BrandSupportTools/FormControlsValidation";
import { ErrorMessage, Field, Formik } from "formik";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

export const clothsDetailsData = ["Special Parameters", "Quiz Materials"];

export interface ExamModalInterface {
    value: boolean;
    setquizopen: (value: boolean) => void;
}

const questions = [
    { id: 1, text: 'What is the capital of France?', options: ['Paris', 'Berlin', 'Madrid', 'Rome'], correct: 0 },
    { id: 2, text: 'What is 2 + 2?', options: ['3', '4', '5', '6'], correct: 1 },
    { id: 3, text: 'Who wrote "Hamlet"?', options: ['Shakespeare', 'Dickens', 'Hemingway', 'Austen'], correct: 0 },
    { id: 4, text: 'What is the largest planet in our solar system?', options: ['Earth', 'Jupiter', 'Mars', 'Venus'], correct: 1 },
    { id: 5, text: 'What is the chemical symbol for gold?', options: ['Au', 'Ag', 'Pb', 'Fe'], correct: 0 },
    { id: 6, text: 'In which year did the Titanic sink?', options: ['1912', '1905', '1920', '1898'], correct: 0 },
    { id: 7, text: 'What is the hardest natural substance on Earth?', options: ['Gold', 'Iron', 'Diamond', 'Platinum'], correct: 2 },
    { id: 8, text: 'Who painted the Mona Lisa?', options: ['Da Vinci', 'Van Gogh', 'Picasso', 'Monet'], correct: 0 },
    { id: 9, text: 'What is the capital of Japan?', options: ['Tokyo', 'Seoul', 'Beijing', 'Bangkok'], correct: 0 },
    { id: 10, text: 'What is the smallest prime number?', options: ['0', '1', '2', '3'], correct: 2 },
    { id: 11, text: 'Who discovered penicillin?', options: ['Fleming', 'Edison', 'Curie', 'Pasteur'], correct: 0 },
    { id: 12, text: 'What is the largest ocean on Earth?', options: ['Atlantic', 'Indian', 'Pacific', 'Arctic'], correct: 2 },
    { id: 13, text: 'What element does "O" represent on the periodic table?', options: ['Oxygen', 'Gold', 'Osmium', 'Oganesson'], correct: 0 },
    { id: 14, text: 'In which continent is Egypt located?', options: ['Asia', 'Africa', 'Europe', 'South America'], correct: 1 },
    { id: 15, text: 'What is the main ingredient in guacamole?', options: ['Tomato', 'Pepper', 'Avocado', 'Onion'], correct: 2 },
    { id: 16, text: 'Who was the first President of the United States?', options: ['Lincoln', 'Washington', 'Jefferson', 'Adams'], correct: 1 },
    { id: 17, text: 'What is the chemical symbol for water?', options: ['HO', 'H2O', 'O2', 'CO2'], correct: 1 },
    { id: 18, text: 'What is the largest land animal?', options: ['Elephant', 'Giraffe', 'Rhino', 'Hippopotamus'], correct: 0 },
    { id: 19, text: 'Which planet is known as the Red Planet?', options: ['Venus', 'Mars', 'Jupiter', 'Saturn'], correct: 1 },
    { id: 20, text: 'What is the capital of Canada?', options: ['Toronto', 'Ottawa', 'Vancouver', 'Montreal'], correct: 1 },
    { id: 21, text: 'Which gas do plants primarily use for photosynthesis?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 2 },
    { id: 22, text: 'Who is known as the "Father of Computers"?', options: ['Turing', 'Babbage', 'Jobs', 'Gates'], correct: 1 },
    { id: 23, text: 'What is the smallest country in the world by land area?', options: ['Vatican City', 'Monaco', 'San Marino', 'Liechtenstein'], correct: 0 },
    { id: 24, text: 'What is the currency of Japan?', options: ['Yuan', 'Yen', 'Won', 'Ringgit'], correct: 1 },
    { id: 25, text: 'Which planet is closest to the Sun?', options: ['Venus', 'Earth', 'Mercury', 'Mars'], correct: 2 },
    { id: 26, text: 'What is the freezing point of water in Celsius?', options: ['0°C', '32°C', '100°C', '-10°C'], correct: 0 },
    { id: 27, text: 'What is the largest species of shark?', options: ['Great White', 'Hammerhead', 'Whale Shark', 'Tiger Shark'], correct: 2 },
    { id: 28, text: 'Who was the 16th President of the United States?', options: ['Lincoln', 'Grant', 'Roosevelt', 'Jackson'], correct: 0 },
    { id: 29, text: 'What is the most abundant gas in Earth’s atmosphere?', options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'], correct: 1 },
    { id: 30, text: 'What is the capital of Australia?', options: ['Sydney', 'Melbourne', 'Canberra', 'Brisbane'], correct: 2 }
];

const ExamModal = ({ value, setquizopen }: ExamModalInterface) => {
    const [open, setOpen] = useState(value);
    const [isOpen, setIsOpen] = useState(value);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [timeLeft, setTimeLeft] = useState(7200); // Set to 2 hours (7200 seconds)
    const [animate, setAnimate] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);
    const [answerLocked, setAnswerLocked] = useState(false); // State to lock answers
    const quizName = "General Knowledge Quiz"; // Hardcoded quiz name

    // Timer effect
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const totalQuestions = questions.length;

    const handleNext = () => {
        if (currentQuestion < totalQuestions) {
            setAnimate(true);
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1);
                setSelectedAnswer(null);
                setCorrectAnswer(null);
                setAnswerLocked(false); // Unlock answers for the next question
                setAnimate(false);
            }, 300);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 1) {
            setAnimate(true);
            setTimeout(() => {
                setCurrentQuestion(currentQuestion - 1);
                setSelectedAnswer(null);
                setCorrectAnswer(null);
                setAnswerLocked(false); // Unlock answers for the previous question
                setAnimate(false);
            }, 300);
        }
    };

    const handleAnswerClick = (index: number) => {
        if (!answerLocked) {
            const correct = questions[currentQuestion - 1].correct;
            setSelectedAnswer(index);
            setCorrectAnswer(correct);
            setAnswerLocked(true); // Lock the answer after selection
        }
    };

    const getButtonClass = (index: number) => {
        if (selectedAnswer === index) {
            return index === correctAnswer ? "btn-success" : "btn-danger";
        }
        return " ";
    };

    const currentQuestionData = questions[currentQuestion - 1];
    const color2 = "#ff6600"; // First color
    const color1 = "#5cbe1c"; // Second color
    const [submitErrors, setSubmitError] = useState<boolean>(false);

    const handleSubmit = (values: QuizMaterialFormValidationProp) => {
        console.log(values);
        setSubmitError(false);
    };
    const { mobileView } = useAppSelector((state) => state.layout);

    // Format timeLeft to HH:MM:SS
    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${secs < 10 ? '0' + secs : secs}`;
    };

    return (
        <Modal
            wrapClassName="product-box"
            fade
            centered
            size="xl"
            isOpen={open}
            toggle={() => setquizopen(false)}
            className="modal-dialog-scrollable">
            <div className="modal-header" onClick={() => setquizopen(false)}>
                <div className="d-flex justify-content-between flex-fill">
                    <H4>{quizName}({`Question: ${currentQuestion}/${totalQuestions}`})</H4>
                    <div className="quiz-info">
                        <H4>
                            <span className="f-right me-5">{`Time Left: ${formatTime(timeLeft)}`}</span>
                        </H4>
                        <Btn className="py-0" close></Btn>
                    </div>
                </div>
            </div>

            <div className="modal-body bg-light">
                <div>
                    <div onClick={() => setIsOpen(!isOpen)} className="d-flex bg-gray rounded-2 mb-2 p-2 gap-2 align-items-center justify-content-between">
                        <div className="d-flex gap-2 align-items-center">
                            <H4 className="text-white">Quiz Material</H4>
                        </div>
                        {isOpen ? <ChevronUp /> : <ChevronDown />}
                    </div>
                    <Collapse isOpen={isOpen}>
                        <CardBody className="py-2 bg-light2 border p-2 mb-2">
                            <Formik
                                initialValues={quizMaterialFormInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={quizMaterialLessonFormValidationSchema}
                            >
                                {({ errors, values, setFieldValue }) => (
                                    <Form className="form-wizard">
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    <span className="txt-black fw-bold">Category:</span> General Knowledge  
                                                </Label>
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check>
                                                    <span className="txt-black fw-bold">Title:</span> Introduction to World History
                                                </Label>
                                                <ErrorMessage
                                                    name="title"
                                                    component="span"
                                                    className="invalid-feedback"
                                                />
                                            </Col>
                                            <Col sm="4" className="mb-3">
                                                <Label check><span className="txt-black fw-bold"> Material Text: </span> 
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className="mb-3">
                                                <Label check><span className="txt-black fw-bold">Attachment: </span> </Label>
                                            </Col> 

                                            <Col sm="4" className="mb-3">
                                                <Label check><span className="txt-black fw-bold">Keywords: </span> history, world, ancient, civilizations</Label> 
                                            </Col>
                                        </Row> 
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Collapse>
                </div>
                <div className="quiz-container">
                    <motion.div
                        key={currentQuestion}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0, backgroundPosition: `100% 0%` }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.5 }}
                        className="question-content"
                        style={{
                            padding: "20px",
                            borderRadius: "8px",
                            backgroundImage: `linear-gradient(135deg, ${color1} 50%, ${color2} 50%)`,
                            backgroundSize: "200% 100%",
                            backgroundPosition: animate ? "100% 0%" : "0% 0%",
                            transition: "background-position 0.5s ease-in-out"
                        }}
                    >
                        <div className="d-flex flex-column  justify-content-center" style={{ marginLeft: mobileView ? "50px" : "125px" }}    >
                            <H4 className="text-white">{currentQuestion}/{totalQuestions}: {" "}{currentQuestionData.text}</H4>
                            <div className="mt-3 d-flex flex-column gap-2">
                                {currentQuestionData.options.map((option, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleAnswerClick(index)}
                                        className={`btn ${getButtonClass(index)} w-100 border py-2 px-3 text-white`}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </motion.div>

                    <footer className="sticky-footer p-2">
                        <div className="d-block d-md-flex justify-content-between">
                        <div className="pt-2 ms-0 d-flex align-items-center">
                            
                            <Formik
                                initialValues={quizMaterialFormInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={quizMaterialLessonFormValidationSchema}
                            >
                                <Form style={{ Width:"400px"}} className="d-flex align-items-center gap-2">
                                <Label className="text-black text-left" check>Question Number:{' '}</Label>
                                                <input
                                                                    type="text"
                                                                    // value={item.quantity}
                                                                    // onChange={(e) =>
                                                                    //     // handleQuantityChange( )
                                                                    // }
                                                                    min="1" 
                                                                    className="form-control"
                                                                    style={{ width: "50px" }}  // Disable if not in edit mode
                                                                />
                                </Form>
                            </Formik>
                            <div className="next_previous pt-0">
                            <button className="btn ms-3" >
                                Jump
                            </button>
                            </div>
                        </div>
                        <div className="next_previous">
                            <button className="btn" onClick={handlePrevious} disabled={currentQuestion === 1}>
                                Previous
                            </button>
                            <button className="btn ms-2" onClick={handleNext} disabled={currentQuestion === totalQuestions}>
                                Next
                            </button>
                        </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Modal>
    );
};

export default ExamModal;
