
import { Card, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs"; 
import {
  BrandSupportTools,
  AddSurveyQuizzes,
} from "../../../../utils/Constant"; 
import SurveyAndQuizzesMain from "../../../../Component/Tools/BrandSupportTools/SurveyAndQuizzes/SurveyAndQuizzesMain";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
const AddSurveyAndQuizzes = () => {

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AddSurveyQuizzes} parent={BrandSupportTools} />
      <SubMenu/>
      <Container  fluid>
        <StudyMaterialSubMenu/>
        <Card className=" p-4">
          <SurveyAndQuizzesMain/>
        </Card>
      </Container>
    </div>
  );
};

export default AddSurveyAndQuizzes;
