// import React, { useState } from 'react';
// import { Card, Col, Row, CardHeader, Container } from 'reactstrap';
// import { Link, useLocation } from 'react-router-dom';
// import { links } from '../../../Data/Tools/BrandSupportTools/ButtonActions';

// const SubMenu = () => {
//     const [activeTab, setActiveTab] = useState("1");
//     const location = useLocation();
    
//     // Function to determine if the path is active
//     const isActive = (path) => {
//         return location.pathname.startsWith(`${process.env.PUBLIC_URL}/${path}`);
//     };

//     return (
//         <div className="email-wrap bookmark-wrap">
//             <Container fluid>
//                 <Row>
//                     <Col md="12">
//                         <Card className='mb-0'>
//                             <CardHeader className='py-1 px-1'>
//                                 <div>
//                                     {links.map((item, index) => {
//                                         const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
//                                         const active = isActive(item.path);

//                                         return (
//                                             <Link
//                                                 to={linkPath}
//                                                 key={index}
//                                                 className={`border d-inline-block p-2 m-1 rounded ${active ? 'sub-active' : ''}`}
//                                             >
//                                                 {item.title} {item.total_contest >= 0 ? `(${item.total_contest})` : ``}
//                                             </Link>
//                                         );
//                                     })}
//                                 </div>
//                             </CardHeader>
//                         </Card>
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     );
// };

// export default SubMenu;
import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { links } from '../../../Data/Tools/BrandSupportTools/ButtonActions';
import { useAppSelector } from '../../../ReduxToolkit/Hooks';

// Replace this with your actual links data
 

const SubMenu = () => {
    const { mobileView } = useAppSelector((state) => state.layout);

    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get the current location

    // Function to determine if the path is active
    const isActive = (path) => {
        return location.pathname.startsWith(`${process.env.PUBLIC_URL}/${path}`);
    };
   
    return (
        <Container fluid>
            <Card className='mb-0'>
                <CardBody className='pb-0 px-0 pt-0'>
                    <Row>
                    {mobileView ? (
                            <>
                                
                        <Col md="12">
                            {isOpen ? (
                                <IoClose size='2em' onClick={() => setIsOpen(false)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                            ) : (
                                <HiOutlineMenuAlt2 size='2em' onClick={() => setIsOpen(true)} className='p-1 rounded-circle' style={{ backgroundColor: '#b6c8e7' }} />
                            )}
                        </Col>
                        <Col md="12">
                            {isOpen ? (
                                <>
                                    {links.map((item, index) => {
                                        const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                        const active = isActive(item.path);
                                        return (
                                            <Link
                                                key={index}
                                                to={linkPath}
                                                className={`p-2 m-1 ps-2 pe-2 border rounded border-primary d-inline-block ${active ? 'sub-active' : ''}`}
                                            >
                                                {item.icon}{item.title}
                                            </Link>
                                        );
                                    })}
                                </>
                            ) : ("")}
                        </Col>
                        </>
                    ) : (
                            <Col md="12">
                                {links.map((item, index) =>{
                                    const linkPath = `${process.env.PUBLIC_URL}/${item.path}`;
                                    const active = isActive(item.path);
                                    return (
                                
                                    <Link
                                    key={index}
                                    to={linkPath}
                                    className={`rounded p-2 m-1 ps-2 pe-2 border border-primary d-inline-block ${active ? 'sub-active' : ''}`}
                                    >
                                        {item.icon}{item.title}
                                    </Link>
                                    )
                                }
                                )}
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default SubMenu;
