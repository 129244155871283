import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LI } from "../../../../AbstractElements";
import ProfileBox from "./ProfileBox";
import { fas } from "@fortawesome/free-solid-svg-icons";

const UserProfile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null); // Create a ref to track the dropdown

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <LI
      className={`profile-nav onhover-dropdown ${
        dropdownOpen ? "active_usericon" : ""
      }`}
      // Attach ref to the LI element
    >
      <div ref={dropdownRef}>
        <div
          className="d-flex profile-media align-items-center"
          onClick={toggleDropdown}
        >
          <FontAwesomeIcon icon={fas.faUser} />
        </div>
        {dropdownOpen && (
          <ProfileBox
            dropdownOpen={dropdownOpen}
            toggleDropdown={toggleDropdown}
          />
        )}
      </div>
    </LI>
  );
};

export default UserProfile;
