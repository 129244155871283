 import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { ManageContents, AddNewAlbums } from "../../../../utils/Constant";   
import AddManageMyAlbums from "./AddManageMyAlbums";

const  ManageMyAlbumsAdd = () => { 

 
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AddNewAlbums} parent={ManageContents} />
     <AddManageMyAlbums />
    </div>
  );
};

export default ManageMyAlbumsAdd;
