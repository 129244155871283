import {useState} from 'react'
import { BrandTools, Lesson, ManageExternalTitle, QuizMaterial, StudyMaterial } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, ExternalLinkTableAction,  StudyMaterialAction, StudyMaterialTableAction, links, supportColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import Popup from '../../../../Component/MasterPopup/Popup'
import StudyMaterialEdit from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialEdit'
import StudyMaterialView from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialView'
import StudyMaterialLessions from './StudyMaterialLessions'

const StudyMaterialLessionPage = () => {
  
   
  
    return (
        <div className='page-body'>
        <Breadcrumbs mainTitle={`${Lesson} `} parent={BrandTools} />
        <StudyMaterialLessions/>
        </div>
    )
  
}

export default StudyMaterialLessionPage