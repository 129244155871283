import { Btn } from '../../../../AbstractElements'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks'
import { setCategoryModal } from '../../../../ReduxToolkit/Reducers/ContactSlice'
import { AddKeywords, Cancel, Save } from '../../../../utils/Constant'
import { Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Typeahead } from "react-bootstrap-typeahead";
import { Formik } from 'formik'
import { manageAlbumFormValidationSchema } from '../../../../Data/Tools/ManageContents/FormControlsValidation'

export const keywords =["All","Personal","Work","Important"]
const CategoryCreate = () => {
  const {categoryModal} =useAppSelector((state)=>state.contact)
  const dispatch = useAppDispatch()
  const categoryToggle = () => dispatch(setCategoryModal())
  const handleSubmit =() =>{}
  return (
    <>
      <Btn color='black' className='badge-light-primary txt-primary btn-mail btn-dark' onClick= {categoryToggle} >
         + {AddKeywords}
      </Btn>
      <Modal fade isOpen={categoryModal} toggle={categoryToggle}>
        <ModalHeader toggle={categoryToggle}>{AddKeywords}</ModalHeader>
        <ModalBody>
        <Formik
          initialValues = {{usename:""}}
          onSubmit={handleSubmit}
          validationSchema={manageAlbumFormValidationSchema}>
          {({ errors, values, setFieldValue }) => (
          <Form className="form-bookmark">
            <Row className='g-2'>
              <Col md="12">
                <FormGroup>
                  {/* <Input type="text" required placeholder={EnterKeywordName} autoComplete="off" /> */}
                  {/* <TagsInput name="keywords" placeholder="Add keywords" setFieldValue={setFieldValue}/> */}
                  <Typeahead id="multiple-typeahead" className="mt-2" labelKey="name" multiple options={keywords} placeholder={`Contact`} />
                </FormGroup>
              </Col>
            </Row>
            <Btn color='secondary' className='me-2' onClick= {categoryToggle}>{Save}</Btn>
            <Btn color='primary' onClick= {categoryToggle }>{Cancel}</Btn>
          </Form>
      )}
      </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CategoryCreate