import { useState } from "react";
import { Row, Col, Label, Container, FormGroup, Button, Table, Input } from "reactstrap"; 
import { ErrorMessage, Field, Form } from "formik"; 
import Branches from "../../../../../CommonInput/Branches";
import City from "../../../../../CommonInput/City";
import State from "../../../../../CommonInput/State";
import Country from "../../../../../CommonInput/Country";
import { SchedulesFormValidationProp } from "../../../../../../Data/Tools/ManageOnlineStore/FormControlsValidation";
import { H3, H4, H6 } from "../../../../../../AbstractElements";
import CategoryDataDrop from "../../../../../CommonInput/CategoryDataDrop";
import CategoryTable from "./CategoryTable";

const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function BranchSetupPrice(props: any) {
  const { errors, submitErrors, setSubmitError } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([false, false]);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const onChangeHandler = () => {
    setAutoSchedule((oldFlag) => !oldFlag);
  }; 
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(selectedRows.map(() => newSelectAll));
  };

  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);

    // If any row is unselected, uncheck the header checkbox
    if (!newSelectedRows.includes(false)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const renderTableRows = () => {
    if (!selectedCategory) return null;
    return data[selectedCategory].map((item, index) => (
      <tr key={index}>
        <th scope="row">
          <Input type="checkbox" />
        </th>
        <td>{item.addon}</td>
        <td>{item.name}</td>
        <td>{item.quantity}</td>
        <td>{item.price}</td>
      </tr>
    ));
  };

  return (
    <>
    <Form className="form-wizard">
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Branch Selection </H3>
        </Col> 
      </Row> 
      <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            Country <span className="txt-danger">*</span>
          </Label> 
          <Country name="country" isMulti={false} placeholder="Select"  className={`${
              submitErrors && `${errors.country ? "is-invalid" : "is-valid"}`
            }`} />
          <ErrorMessage
            name="country"
            component="span"
            className="invalid-feedback"
          />
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>State</Label> 
          <State name='state' isMulti={false} placeholder="Select"/>
        </Col>
        <Col sm="4" className="mb-3">
          <Label check>City</Label>
          <City name='city' isMulti={false} placeholder='Select'/>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="4" className="mb-3">
          <Label check>Branch</Label>
          <Branches name="branch" isMulti={true} placeholder="Select"/>
        </Col> 
        <Col sm="4" className="mt-2">
           <Button className="btn btn-primary">
            Select Branch
           </Button>
        </Col>
      </Row> 
      <Row>
      <Col sm="4" className="mb-3">
          <Label check>
            Final Branch List <span className="txt-danger">*</span>
          </Label> 
          <ErrorMessage
            name="finalBranchList"
            component="span"
            className="invalid-feedback"
          />
        </Col> 
      </Row>

      
    </Form>
    <Form className="form-wizard">
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Price</H3>
        </Col> 
      </Row>   
    </Form>
    <Form className="form-wizard">
        <Row>
        <Col sm="8" className="mb-3">
          <H3 className="text-gray">Addons</H3>
        </Col> 
      </Row> 
      <CategoryTable />
      {/* <Row>
        <Col sm="4" className="mb-3">
          <Label check>
            Addon Category <span className="txt-danger">*</span>
          </Label> 
          <CategoryDataDrop name="category" isMulti={true} placeholder="Select"  className={`${
              submitErrors && `${errors.country ? "is-invalid" : "is-valid"}`
            }`} />
          <ErrorMessage
            name="country"
            component="span"
            className="invalid-feedback"
          />
        </Col> 
        <Col>
        <Button className="btn-primary" >
          + Another Addon Category 
        </Button>
        </Col>
      </Row> */} 
  
    </Form>
    {/*<Row className="mb-4">
    <Table bordered>
      <thead>
        <tr>
          <th>
            <Input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </th>
          <th>Addon</th>
          <th>Option Name</th>
          <th>Addon Quantity</th>
          <th>Addon Extra Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <Input
              type="checkbox"
              checked={selectedRows[0]}
              onChange={() => handleRowSelect(0)}
            />
          </th>
          <td>Addon 1</td>
          <td>Mineral water</td>
          <td>1ltr</td>
          <td>1$</td>
        </tr>
        <tr>
          <th scope="row">
            <Input
              type="checkbox"
              checked={selectedRows[1]}
              onChange={() => handleRowSelect(1)}
            />
          </th>
          <td>Addon 2</td>
          <td>Coca Cola</td>
          <td>600ml</td>
          <td>2$</td>
        </tr>
      </tbody>
    </Table>
      </Row> */}
    </>
  );
}

export default BranchSetupPrice;
