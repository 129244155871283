import {useState} from 'react'
import { BrandTools, Lesson, ManageExternalTitle, QuizMaterial, StudyMaterial } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable, { TableColumn } from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import { ActionButtons, ExternalLinkTableAction,  StudyMaterialAction, StudyMaterialTableAction, links, supportColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import { SupportDataType } from '../../../App/AccountSettings/MyResumes'
import { TableHeadColumn } from '../../../App/AccountSettings/MyRegistrationPlan'
import Popup from '../../../../Component/MasterPopup/Popup'
import StudyMaterialEdit from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialEdit'
import StudyMaterialView from '../../../../Component/Tools/BrandSupportTools/StudyMaterialLesson/StudyMaterialView'
import SubMenu from '../../../../Component/Application/SubMenu/SubMenu'
import StudyMaterialSubMenu from '../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu'

const StudyMaterialLessions = () => {
  
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);
    const [isEditOpen , setIsEditOpen] = useState(false);
    const [isOpen , setIsOpen] = useState(false);
  
    const handleRowSelected =() =>{}

    const openPopup = (type:boolean) =>{
        setIsOpen(!isOpen);
        setIsEditOpen(type);
    }

    const openModalToggle = () =>{
        setIsOpen(!isOpen);
    }

    const StudyMaterialColumnData:TableColumn<SupportDataType>[] = [
        {
            name: <TableHeadColumn title={`S. No.`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Material Title`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Sub Categories`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Keywords`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Auto Publish Date`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Attachment`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name: <TableHeadColumn title={`Urls`}/>,
            selector: (row) => row["id"],
            sortable: true,
            center: false,
        },
        {
            name:<TableHeadColumn title={`Actions`}/>,
            cell: (row) => <ActionButtons openPopup={openPopup}/>,
            center: false,
            right:true,
        }
    ]
  
    return (
         <>
        <SubMenu/>
        <Container fluid>
            <Row>
            <Col sm="12">
                <Card>
                {/* <CardHeader className='pb-0'> 
                    <div>
                    {links.map((item, index)=>{
                    return(
                    <>
                        <Link to={`${process.env.PUBLIC_URL}/${item.path}`}><span className='border p-2 m-1'>{item.title} {item.total_contest >= 0 ? `(${item.total_contest})`:``}</span></Link>
                    </>)
                    })}
                    </div>
                </CardHeader> */}
                <CardBody>
                    <>
                    <div className='pb-2'> 
                    <StudyMaterialSubMenu />
                  </div>
                    <div className='p-2 border'>
                    {/* <Row><Col md="2" className='d-flex'> */}
                        <div className=''>
                        {StudyMaterialTableAction.map((action, index)=>{
                        return(
                        <>
                            <Link to={`${process.env.PUBLIC_URL}${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                        </>)
                        })}
                        </div>
                    {/* </Col></Row> */}
                        <div className="table-responsive pt-2">
                            <DataTable className='custom-scrollbar' columns={StudyMaterialColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                        </div>
                        <Popup title={`Study Material`} openModalToggle={openModalToggle} isOpen={isOpen} size={`xl`}>
                        {isEditOpen ? <StudyMaterialEdit/> : <StudyMaterialView /> }
                        </Popup>
                    </div>
                    </>
                </CardBody>
                </Card>
            </Col>
            </Row>
        </Container> 
         </>
    )
  
}

export default StudyMaterialLessions