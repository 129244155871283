import { Link } from "react-router-dom";
import { FeatherIcons, H5, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { CheckAll, Href } from "../../../../utils/Constant";
import { infoData } from "../../../../Data/LayoutData/HeaderData";

const InfoBox = () => {
    
  return (
    <UL className="simple-list ">
        {infoData.map((data,index) => (
            <LI key={index} className="py-2 px-0 text-left">
                <div className="d-flex align-items-start"> 
                    <div className="flex-grow-1">
                        <H5 className="mb-1 text-start">
                            <Link to={Href}>{data.userName}</Link>
                        </H5> 
                    </div> 
                </div>
            </LI>
        ))} 
    </UL>
  );
};

export default InfoBox;
