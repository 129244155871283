import {useState} from 'react'
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { Btn, H1, H3, H6 } from '../../../../AbstractElements'
import Popup from '../../../MasterPopup/Popup';
import AddPaymentMethod from './AddPaymentMethod';
import { Link } from 'react-router-dom';
import PageHeader from '../../../Dashboard/Default/PageHeader/PageHeader';

const PaymentMethodsContainer = () => {
    const [openModal,  setOpenModal] = useState(false);
  return (
    <Container fluid>
        <Row>
        <PageHeader />
            
            <Col>
                <Card>
                    <Row className='px-3 py-3'>
                        <Col xl="12"><Link to={`${process.env.PUBLIC_URL}/dashboard/payment-methods/add-new`} className='me-1 border rounded p-2 m-1 ps-2 pe-2 bg-dark text-light' onClick={()=>setOpenModal(!openModal)}>{`Add a New Payment Method`}</Link></Col>
                        {/* <Col xl="12" className='d-flex justify-content-between ps-2 pe-2'><H1>{`New`}</H1></Col> */}
                        {/* <Popup title={`New Payment Method`} openModalToggle={()=>setOpenModal(!openModal)} isOpen={openModal} size={`xl`} backdrop={true} isClose={false}>
                            <AddPaymentMethod setOpenModal={setOpenModal} />
                        </Popup> */}
                    </Row>
                </Card>
                <CardBody>
                    <Row className="shopping-wizard">
                    <Col xl="8" sm="12" className="">
                      <div className='shipping-wizard'>
                        <Row className="g-3">
                            <Col xxl="4" sm="6" >
                                <div className="card-wrapper border rounded-3 h-100 light-card">
                                    <div className="collect-address d-flex justify-content-between">
                                    <div className="d-flex gap-2 align-items-center">
                                        <FormGroup check className="radio radio-primary ps-3">
                                        <Input id={``} type="radio" name="address" value={``} checked />
                                        <Label className="form-check-label mb-0" for={`Home`}>{`Default`}</Label>
                                        </FormGroup>
                                    </div>
                                    <div className="card-icon">
                                        <i className="fa fa-pencil p-1" />
                                        <i className="fa fa-trash-o p-1" />
                                    </div>
                                    </div>
                                    <div className="shipping-address">
                                        <H3 className='py-1'>{`Credit Card`}</H3>
                                        <H6>{`Holder Name`}</H6>
                                        <span>{`XXXX XXXX XXXX 9852`}</span><br/>
                                        <span>{`07/30`}</span>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl="4" sm="6" >
                                <div className="card-wrapper border rounded-3 h-100 light-card">
                                    <div className="collect-address d-flex justify-content-between">
                                    <div className="d-flex gap-2 align-items-center">
                                        <FormGroup check className="radio radio-primary ps-3">
                                        <Input id={``} type="radio" name="address" value={``} checked />
                                        <Label className="form-check-label mb-0" for={`Home`}>{`Default`}</Label>
                                        </FormGroup>
                                    </div>
                                    <div className="card-icon">
                                        <i className="fa fa-pencil p-1" />
                                        <i className="fa fa-trash-o p-1" />
                                    </div>
                                    </div>
                                    <div className="shipping-address">
                                        <H3 className='py-1'>{`Debit Card`}</H3>
                                        <H6>{`Holder Name`}</H6>
                                        <span>{`XXXX XXXX XXXX 7854`}</span><br/>
                                        <span> {`07/30`}</span>
                                    </div>
                                </div>
                            </Col>          
                        </Row>
                      </div>
                    </Col>
                    </Row>
                </CardBody>
            </Col>
        </Row>
    </Container>
  )
}

export default PaymentMethodsContainer