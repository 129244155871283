import React, { Fragment, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { Col } from 'reactstrap';
import CalendarEvents from './CalendarEvents';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { calenderInitialData } from '../../../../Data/Application/Calendar/Calendar';
import { calenderEventClick } from './CalenderFunction';

const DragCalendar = () => {
  const [state, setState] = useState(calenderInitialData);
  const [draggedEventInfo, setDraggedEventInfo] = useState({ title: '', start: '', end: '' });
  const [events, setEvents] = useState([
    {
      title: 'Event 1',
      start: '2024-09-15T10:00:00',
      backgroundColor: '#ff5733', // Red
      borderColor: '#ff5733',
    },
    {
      title: 'Event 2',
      start: '2024-09-15T12:00:00',
      backgroundColor: '#33ff57', // Green
      borderColor: '#33ff57',
    },
    {
      title: 'Event 3',
      start: '2024-09-15T14:00:00',
      backgroundColor: '#3357ff', // Blue
      borderColor: '#3357ff',
    },
    {
      title: 'Event 4',
      start: '2024-09-15T16:00:00',
      backgroundColor: '#f3ff33',  
      borderColor: '#f3ff33',
    },
  ]);

  function handleEventResize(info) {
    console.log("sdsds", info.event.end);

    const event = info.event;
  }

  function handleEventReceive(info) {
    handleEventResize(info);
    const event = info.event;

    // Debugging: Log the event data
    console.log("Received Event:", event);

    // Ensure the event has a start date
    if (event.start) {
      setDraggedEventInfo({
        title: event.title,
        start: event.start.toLocaleString(),
        end: event.start.toLocaleString()
      });
    } else {
      setDraggedEventInfo({
        title: event.title,
        start: 'No start time',
        end: event.start.toLocaleString()
      });
    }
  }

  function handleEventDrop(info) {
    const event = info.event;

    // Debugging: Log the event data
    console.log("Dropped Event:", event.start.toLocaleString());

    // Ensure the event has a start date
    if (event.start) {
      setDraggedEventInfo({
        title: event.title,
        start: event.start.toLocaleString(),
        end: event.start.toLocaleString()
      });
    } else {
      setDraggedEventInfo({
        title: event.title,
        start: 'No start time',
        end: event.start.toLocaleString()
      });
    }
  }

  function updateEvent(id, start, end) {
    const eventIndex = state.calendarEvents.findIndex(e => e.id === id);
    console.log(eventIndex);
    if (eventIndex > -1) {
      const updatedEvents = [...state.calendarEvents];
      updatedEvents[eventIndex].start = start;
      updatedEvents[eventIndex].end = end;
      setState(prevState => ({
        ...prevState,
        calendarEvents: updatedEvents
      }));
    }
  }

  useEffect(() => {
    let draggableEl = document.getElementById('external-events');
    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        let title = eventEl.getAttribute('title');
        let id = eventEl.getAttribute('data');
        return {
          title: title,
          id: id,
        };
      },
    });
  }, []);

  return (
    <Fragment>
      <CalendarEvents />
      <Col xl="8" lg="9" className="box-col-8">

        <div className="demo-app-calendar" id="mycalendartest">
          <FullCalendar
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            rerenderDelay={10}
            eventDurationEditable={true}
            editable={true}
            droppable={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            events={calenderInitialData.calendarEvents}
            eventClick={calenderEventClick}
            eventResize={handleEventResize}
            eventReceive={handleEventReceive}  // Handle event when received
            eventDrop={handleEventDrop}        // Handle event when dropped
            eventContent={(arg) => (
              console.log(arg), 
              <div style={{
                backgroundColor: arg.backgroundColor,
                borderColor: arg.borderColor,
                padding: '5px',
                color: '#000',
                borderRadius: '5px',
                textAlign: 'center',
                width:"100%"
              }}>
                {arg.event.title}
              </div>
            )}
          />
        </div>
      </Col>
    </Fragment>
  );
}

export default DragCalendar;
