import React from 'react'
import { CreateAPost, Post } from '../../../../utils/Constant'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import CreatePostContainer from '../../../../Component/App/Post/CreatePostContainer'
import PostsubMenu from '../../../../Component/App/PostSubMenu/PostsubMenu'

const CreatePost = () => {
  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={CreateAPost} parent={Post} />
      <PostsubMenu/>
      <CreatePostContainer/>
    </div>
  )
}

export default CreatePost

