import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FeatherIcons, LI, UL } from "../../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, IconDefinition } from "@fortawesome/free-solid-svg-icons";

import { useLogoutMutation } from "../../../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { logout } from "../../../../ReduxToolkit/Reducers/Authentication/authSlice";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../../Component/Loading/Loading";
export interface adminDropdownType {
  name: string;
  icon: IconDefinition;
  link?: string;
  btnname: string;
  children?: {
    name: string;
    link: string;
    icon?: IconDefinition; // Optional icon property for child items
  }[];
}

export const adminDropdown: any = [
  {
    name: "Username1",
    icon: fas.faUser,
    link: `${process.env.PUBLIC_URL}/panel/admin`,
  },
  {
    btnname: "Download App",
  },
  {
    name: "Dashboard",
    icon: fas.faTachometerAlt,
    children: [
      {
        name: "User Dashboard",
        link: `${process.env.PUBLIC_URL}/dashboard/user-dashboard`,
      },
      {
        name: "Payment Methods",
        link: `${process.env.PUBLIC_URL}/dashboard/payment-methods/`,
      },
      {
        name: "Withdrawals",
        link: `${process.env.PUBLIC_URL}/dashboard/withdrawals`,
      },
      {
        name: "My Purchases",
        link: `${process.env.PUBLIC_URL}/dashboard/my-purchases`,
      },
      { name: "Sales", link: `${process.env.PUBLIC_URL}/dashboard/my-sales` },
      {
        name: "Referrals",
        link: `${process.env.PUBLIC_URL}/dashboard/referrals`,
      },
    ],
  },
  {
    name: "Account Settings",
    icon: fas.faGear,
    children: [
      {
        name: "Personal Information",
        link: `${process.env.PUBLIC_URL}/account-settings/personal-information`,
      },
      {
        name: "Resumes",
        link: `${process.env.PUBLIC_URL}/account-settings/my-resumes`,
      }, 
      {
        name: "Shipping Addresses",
        link: `${process.env.PUBLIC_URL}/account-settings/shipping-addresses`,
      },
      {
        name: "Password",
        link: `${process.env.PUBLIC_URL}/account-settings/password`,
      },
      {
        name: "Registration Plan",
        link: `${process.env.PUBLIC_URL}/account-settings/my-registration-plan`,
      },
    ],
  },
  {
    name: "Other Accounts",
    icon: fas.faUser,
    children: [
      {
        name: "Username1",
        icon: fas.faUser,
        link: `${process.env.PUBLIC_URL}/panel/admin`,
      },
      {
        name: "Username2",
        icon: fas.faUser,
        link: `${process.env.PUBLIC_URL}/panel/admin`,
      },
      {
        name: "Username3",
        icon: fas.faUser,
        link: `${process.env.PUBLIC_URL}/panel/admin`,
      },
    ],
  },
  {
    name: "Log Out",
    icon: fas.faArrowRightFromBracket,
  },
];

const ProfileBox = ({
  dropdownOpen,
  toggleDropdown,
}: {
  dropdownOpen: boolean;
  toggleDropdown: () => void;
}) => {
  const [logoutApiCall, { isLoading : logoutLoading}] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [activeAccordion, setActiveAccordion] = useState<string | null>(
    "Other Accounts"
  );
  const [selectedUser, setSelectedUser] = useState<string | null>("Username1");

  const handleClick = (name: string) => {
    if (name === "Log Out") {
      localStorage.removeItem("login");
    }
    toggleDropdown(); // Close the dropdown after clicking an item
  };

  const handleAccordionToggle = (name: string) => {
    setActiveAccordion(activeAccordion === name ? null : name);
  };

  const handleUserSelect = (name: string) => {
    setSelectedUser(name);
  };

  useEffect(() => {
    const firstItem = adminDropdown[0];
    if (firstItem) {
      setSelectedUser(firstItem.name);
    }
  }, []);

  const logoutUser = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout()); 
      toast.success("Logout Succesfully", { position: "bottom-right" });

      navigate("/");
    } catch (error) {
      toast.error("Something went wrong!",{ position: "bottom-right" });
    }
  };

  return (
    <UL
      className={`profile_drop simple-list  show_profile_drop profile-dropdown `}
    >
      {adminDropdown.map((data: any, index: number) => {
        let content;
        if (data.name === "Log Out") {
          content = (
            <div
              onClick={logoutUser}
              className={`d-flex align-items-center flex-column selected-user gap-2`}
            >
              <FontAwesomeIcon icon={data.icon} />
              <span>{data.name}</span>
            </div>
          );
          content = logoutLoading ? <Loading/> : content;
        } else {
          content = (
            <Link
              to={data.link!}
              onClick={() => handleClick(data.name)}
              className={`d-flex align-items-center flex-column selected-user gap-2 ${
                selectedUser === data.name ? "selected" : ""
              }`}
            >
              <FontAwesomeIcon icon={data.icon} />
              <span>
                {selectedUser === data.name ? `Hi, ${data.name}!` : data.name}
              </span>
            </Link>
          );
        }

        return (
          <LI key={index}>
            {data.children ? (
              <>
                <div
                  onClick={() => handleAccordionToggle(data.name)}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={data.icon} />
                    <span>{data.name}</span>
                  </div>
                  {data.name === "Other Accounts" ? (
                    <span></span>
                  ) : (
                    <FeatherIcons
                      iconName={
                        activeAccordion === data.name
                          ? "ChevronDown"
                          : "ChevronRight"
                      }
                    />
                  )}
                </div>
                {activeAccordion === data.name && (
                  <UL className="submenu">
                    {data.children.map((child: any, childIndex: number) => {
                      let content = (
                        <Link
                          to={child.link}
                          onClick={() => handleClick(child.name)}
                          className="d-flex align-items-center"
                        >
                          {child.icon && <FontAwesomeIcon icon={child.icon} />}
                          <span>{child.name}</span>
                        </Link>
                      );
                      return (
                        <LI
                          key={childIndex}
                          className={
                            selectedUser === child.name ? "selected" : ""
                          }
                          onClick={() => handleUserSelect(child.name)}
                        >
                          {content}
                        </LI>
                      );
                    })}
                  </UL>
                )}
              </>
            ) : (
              <>
                {data.btnname && (
                  <div className="text-start  d-block">
                    <Link to="/" className="bg-success px-3 py-2 d-block">
                      {data.btnname}
                    </Link>
                  </div>
                )}
                {content}
              </>
            )}
          </LI>
        );
      })}
    </UL>
  );
};

export default ProfileBox;
