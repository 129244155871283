import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DeliveryFeeAction } from '../../../../Data/Tools/ManageContents/ManageContentButtons';

const DeliverySubMenu = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => {
        return path && currentPath.startsWith(`${process.env.PUBLIC_URL}/${path}`);
    };

    return (
        <div className='d-flex'>
            {DeliveryFeeAction.map((item, index) => {
                // Determine the active class only if the item has a path
                const activeClass = item.path && isActive(item.path) ? '' : '';
                const isChecked = item.path && isActive(item.path);

                return (
                    <div key={index} className={` p-2 m-1 delivery_input ${activeClass}`}>
                        <Link
                            to={`${process.env.PUBLIC_URL}/${item.path}`}
                            className="text-decoration-none text-reset d-flex align-items-center"
                        >
                            {/* Radio button */}
                            <input
                                type="radio"
                                name="deliveryFee"
                                className="me-2"
                                value={item.path}
                                checked={isChecked} // Set checked state based on whether the path is active
                                readOnly // Make the radio button read-only to prevent user interaction
                            />

                            {/* Item text */}
                            <span>
                                {item.actionName}
                            </span>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default DeliverySubMenu;
