import { Card, Container } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import {
  BrandSupportTools,
  AddAQuizMaterial,
} from "../../../../utils/Constant"; 
import QuizMaterialMain from "../../../../Component/Tools/BrandSupportTools/QuizMaterial/QuizMaterialMain";
import SubMenu from "../../../../Component/Application/SubMenu/SubMenu";
import StudyMaterialSubMenu from "../../../../Component/Application/StudyMaterialSubMenu/StudyMaterialSubMenu";

const AddQuizMaterial = () => {
  

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={AddAQuizMaterial} parent={BrandSupportTools} />
      <SubMenu/>
      <Container  fluid>
      <div className='pb-2'> 
         <StudyMaterialSubMenu />
        </div>
      <Card className="p-4">
          <QuizMaterialMain addQuestions={true}/>
      </Card>
      </Container>
    </div>
  );
};

export default AddQuizMaterial;
