import { useState } from "react";
import { CardBody, Col, Row } from "reactstrap";
import { Formik } from "formik";
import {
  RegistrationPlanValidationProp,
  registratinoFormInitialValue,
  registrationFormSchema,
} from "../../Data/RegistrationPlans/RegistrationValidation";
import StaticPlanData from "./StaticPlanData";
import DynamicPlanData from "./DynamicPlanData";
import Loading from "../Loading/Loading";
import FilterForm from "./FilterForm";
import Message from "../CommonMessage/Message"; 

const PricingPlans = ({allregistrationplans} : any ) => {
  
  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false); 
  const [planData, setPlanData] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [countryId, setCountryId] = useState();


  const handleSignup = (values: RegistrationPlanValidationProp) => {
    console.log(values); 
  };

  if (isLoading) return <Loading />;

  return (
    <CardBody className="pt-0 mt-4"> 
      <>
        <Row className="pt-2">
          <Formik
            initialValues={registratinoFormInitialValue}
            onSubmit={handleSignup}
            validationSchema={registrationFormSchema}
          >
            {({ errors, values, setFieldValue }) => (
              <FilterForm
                errors={errors}
                values={values}
                submitErrors={submitErrors}
                setSubmitError={setSubmitError} 
                setPlanData={setPlanData}
                setIsLoading={setIsLoading}
                setFieldValue={setFieldValue}
                setCountryId={setCountryId}
              />
            )}
          </Formik>
        </Row>
        {Object.keys(planData).length !== 0   ? (
          <Row className="py-1">
            {isMobile ? (
              <Col
                md="12"
                className="d-flex ps-0"
                style={{ overflowX: "auto" }}
              >
                <StaticPlanData staticData={planData} allregistrationplans={allregistrationplans}/> 
                <DynamicPlanData dData={planData} isButton={true} countryId={countryId} /> 
              </Col>
            ) : (
              <>
                <Col md="4" className="ps-0">
                  <StaticPlanData staticData={planData} />
                </Col>
                <Col md="8">
                  <Row>
                    <Col
                      md="12"
                      className="d-flex w-100"
                      style={{
                        overflowX: "scroll",
                        transform: 'rotateX(180deg)',
                        scrollbarWidth: "initial",
                        marginTop: '1.4rem',
                        scrollbarColor: "#000",
                      }}
                    >
                      <div className="d-flex w-auto" style={{ transform: 'rotateX(180deg)' }}>
                        <DynamicPlanData dData={planData} allregistrationplans={allregistrationplans}  isButton={true}  countryId={countryId}/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        ) : (
          <Message message="Select country and plan type to see plan list"/>
        )}
      </>
    </CardBody>
  );
};

export default PricingPlans;
