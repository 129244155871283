import {useState} from 'react'
import { DeliveryFee, ManageAlbums } from '../../../../utils/Constant'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  DeliveryFeesColumnData,  supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  DeliveryFeeAction, DeliveryFeeTableAction, ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import DeliverySubMenu from '../DeliverySubMenu/DeliverySubMenu'

const DeliveryFees = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={DeliveryFee} parent={`Manage Online Store`} />
      <Container fluid>  
            <CardHeader className='pb-0 pt-0  '>  
                    <ManageOnlineSubMenu />
                    </CardHeader>
                    </Container>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card> 
              <CardBody className='py-2'>
                <>  
                <div>
                <DeliverySubMenu/>
                </div>

                <div className='p-2 border mt-2'>
                    <div className=''>
                    {DeliveryFeeTableAction.map((action, index)=>{
                    return(
                    <>
                        <Link to={`${process.env.PUBLIC_URL}/${action.path}`}><span key={index} className='border rounded p-2 m-1 text-white bg-dark ps-2 pe-2'>{action.actionName} </span></Link>
                    </>)
                    })}
                    </div>
                
                   
                    <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={DeliveryFeesColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                    </div>
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DeliveryFees