import * as Yup from "yup";
import { RequiredFieldValidationText } from "../../utils/Constant";
export interface SignupValidationProp {
  username: string;
  email: string;
  password: string;
  password_confirmation: string;
  country_id: any;
  subscription_type: any;
  plan_id: any;
  agreeTermsConditions: any;
  pay_period: string;
}

export const signupFormInitialValue: SignupValidationProp = {
  username: "",
  email: "",
  password: "",
  password_confirmation: "",
  country_id: "",
  plan_id: "",
  subscription_type: "", 
  agreeTermsConditions: '',
  pay_period: 'monthly',
};

export const signupFormSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  password_confirmation: Yup.string().required("Required"),
  country_id: Yup.string().required("Required"),
  subscription_type: Yup.array().min(1).required(RequiredFieldValidationText),
  plan_id: Yup.string().required("Required"), 
  agreeTermsConditions : Yup.string().required("Required"), 
  pay_period: Yup.string().required("Required"), 
});
