import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {  Categorys, DefaultPublicResume, Education, HobbiesAndInterest, Href, JobCancelButton, JobDesiredHeading, JobSubmitButton, JobUIDesigner, JobUploadYourFiles, Languages, Objective, ResumeName, ResumeType, SaveThisJob, Skill, SkillNExperience, Training, UploadCoverLetterJob, UploadRecommendationsJob, WorkExperience } from  '../../../../utils/Constant'
import CommonSwitchSpan from '../../../Forms/FormsWidgets/Switch/Common/CommonSwitchSpan'
import { ErrorMessage, Field } from 'formik';
import { Btn, H3, Image } from '../../../../AbstractElements';
import { dynamicImage } from '../../../../Service';
import { Link } from 'react-router-dom';
import TextCounter from '../../../CommonInput/TextCounter';
import TagsInput from '../../../CommonInput/TagsInput';

const GeneralInformation = (props: any) => {
  const { errors, values, submitErrors, setSubmitError , setFieldValue} = props;

  return (

    <Form className="theme-form">
      
      <Row>
        <Col xl="4" md="4">          
            <Label check>{ResumeName}:<span className="font-danger">*</span></Label>
            <Field type="text" placeholder={ResumeName}  name="resumeName" className={`form-control ${submitErrors && `${errors.resumeName ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
            <TextCounter length={values?.resumeName?.length} totalLength="140" />
            <ErrorMessage
              name="resumeName"
              component="span"
              className="invalid-feedback"
            />          
        </Col>
        <Col xl="4" md="4">
          <FormGroup>
            <Label check>{DefaultPublicResume}:<span className="font-danger">*</span></Label>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0  text-end icon-state switch-outline">
                    <CommonSwitchSpan color={`primary`} />
                </div>
                {/* <Label className="m-l-10" check>{`Two-Step Authentication`}</Label> */}
            </div>
          </FormGroup>
        </Col>
        <Col xl="4" md="4">
          <FormGroup>
            <Label check>{ResumeType}:<span className="font-danger">*</span></Label>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0  text-end icon-state switch-outline">
                    <CommonSwitchSpan color={`primary`} />
                </div>
                {/* <Label className="m-l-10" check>{`Two-Step Authentication`}</Label> */}
            </div>
          </FormGroup>
        </Col>
        <Col xl="4" md="4">
          
            <Label check>{JobDesiredHeading}:<span className="font-danger">*</span></Label>
            {/* <Field type="text" placeholder={JobDesiredHeading}  name="jobTitle" className={`form-control ${submitErrors && `${errors.jobTitle ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/> */}
            <TagsInput
            name="keywords"
            placeholder={JobDesiredHeading}
            setFieldValue={setFieldValue}
          />
            <ErrorMessage
              name="jobTitle"
              component="span"
              className="invalid-feedback"
            />  
          
        </Col>
        
        <Col xl="4" md="4">          
            <Label check>{Objective}:</Label>
            <Field type="text" placeholder={Objective}  name="objective" className={`form-control ${submitErrors && `${errors.objective ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
            <ErrorMessage
              name="objective"
              component="span"
              className="invalid-feedback"
            />  
        </Col>
        <Col xl="4" md="4" className=' my-2'>
            <Label>{Categorys}<span className="font-danger">*</span></Label>
            <Field
              as="select"
              name="subCategory"
              className={`form-control ${
                submitErrors &&
                `${errors.subCategory ? "is-invalid" : "is-valid"}`
              }`}
            >
            <option>{'Select'} {Categorys}</option>
            <option>{'Germany'}</option>
            <option>{'Canada'}</option>
            <option>{'Usa'}</option>
            <option>{'Aus'}</option>
            </Field>
            <ErrorMessage
              name="subCategory"
              component="span"
              className="invalid-feedback"
            />  
        </Col>
        <Col md="4" className='my-2'>
          <FormGroup>
                  <Label className='w-100' check>{`Summery`}  :</Label>
                  {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                  <Field
                      as="textarea"
                      name="description"
                      className="form-control"
                      maxLength={'300'}
                      placeholder="Description/Summary"
                  />
                  <TextCounter length={values?.description?.length} totalLength="300" />
                </FormGroup>
        </Col>
        <Col md="12" className='mt-3 mb-4'><H3 className='mb-0'>{SkillNExperience}</H3></Col>
        <Col xl="4" md="4" >
          <Label>{Languages}</Label>
            <Field
            as="select"
            name="languages"
            className={`form-control ${
              submitErrors &&
              `${errors.languages ? "is-invalid" : "is-valid"}`
            }`}
          >
            <option>{'Select'} {Languages}</option>
            <option>{'Germany'}</option>
            <option>{'Canada'}</option>
            <option>{'Usa'}</option>
            <option>{'Aus'}</option>
            </Field>
            <ErrorMessage
              name="languages"
              component="span"
              className="invalid-feedback"
            />  
          </Col>

          
          <Col xl="4">
              <Label check>{WorkExperience}:<span className="font-danger">*</span></Label>
              <Field type="text" placeholder={WorkExperience}  name="workExperience" className={`form-control ${submitErrors && `${errors.workExperience ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
              <ErrorMessage
                name="workExperience"
                component="span"
                className="invalid-feedback"
              />  
          </Col>
          <Col xl="4">
              <Label check>{Education}:</Label>
              <Field type="text" placeholder={Education}  name="education" className={`form-control ${submitErrors && `${errors.education ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
              <ErrorMessage
                name="education"
                component="span"
                className="invalid-feedback"
              />
          </Col>
          <Col xl="4">
              <Label check>{Training}:</Label>
              <Field type="text" placeholder={Training}  name="traning" className={`form-control ${submitErrors && `${errors.training ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
              <ErrorMessage
                name="traning"
                component="span"
                className="invalid-feedback"
              /> 
          </Col>
          <Col xl="4">
              <Label check>{Skill}:</Label>
              <Field type="text" placeholder={Skill}  name="skill" className={`form-control ${submitErrors && `${errors.skill ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
              <ErrorMessage
                name="skill"
                component="span"
                className="invalid-feedback"
              /> 
          </Col>
          <Col xl="4">
              <Label check>{HobbiesAndInterest}:</Label>
              <Field type="text" placeholder={HobbiesAndInterest}  name="hobbiesInterest" className={`form-control ${submitErrors && `${errors.hobbiesInterest ? "is-invalid" : "is-valid"}`}`} autoFocus={true}/>
              <ErrorMessage
                name="hobbiesInterest"
                component="span"
                className="invalid-feedback"
              /> 
          </Col>
          <Col md="12" className='mt-3 mb-4'><H3 className='mb-0'>{JobUploadYourFiles}</H3></Col>
          <Col xl="4">
            <Label className="col-form-label pt-0" check>{UploadCoverLetterJob}:</Label>
            <Input type="file" />
            <ErrorMessage
                name="hobbiesInterest"
                component="span"
                className="invalid-feedback"
              /> 
          </Col>
          <Col xl="4">
            <Label className="col-form-label pt-0" check>{UploadRecommendationsJob}:</Label>
            <Input type="file" />
            <ErrorMessage
                name="hobbiesInterest"
                component="span"
                className="invalid-feedback"
              /> 
          </Col>
    </Row>

    <Row className='mt-4'>
      <Col>
        <Btn color="primary mx-1" onClick={() => setSubmitError(true)}>{JobSubmitButton}</Btn>
        <Btn color="light">{JobCancelButton}</Btn>
      </Col>
    </Row>
    
    </Form>
  )
}

export default GeneralInformation