import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { ErrorMessage, Field, Formik } from "formik";
import { Btn, H3, H5 } from "../../../AbstractElements";
import {
    Description,
    InviteUsers,
    Keywords,
    PostContent,
    PostTitlePlaceholder,
    WhoCanSee,
} from "../../../utils/Constant";
import CommonModal from "../../Ui-Kits/Modal/Common/CommonModal";
import { useState } from "react";
import InviteContactMain from "../../Communication/InviteContactMain";
import GroupMain from "../../Communication/GroupMain";
import { Typeahead } from "react-bootstrap-typeahead";
import { postTypeData } from "../../Miscellaneous/Blog/AddPost/AddPost";
import Country from "../../CommonInput/Country";
import TextCounter from "../../CommonInput/TextCounter";
import TagsInput from "../../CommonInput/TagsInput";
import {
    createPostInitialValue,
    createPostSchema,
} from "../../../Data/Post/CreatePostValidationSchema";
import { SimpleMdeReact } from "react-simplemde-editor";
// import RadioTypeForm from "../../Miscellaneous/Blog/AddPost/RadioTypeForm"
import * as Yup from "yup";
import CreatePostForm from "./CreatePostForm";
import CustomSelect from "../../CommonInput/CustomSelect";
import City from "../../CommonInput/City";

export const postschema = Yup.object().shape({});
const CreatePostContainer = () => {
    const [postType, setPostType] = useState<number | null>(1);
    const [submitErrors, setSubmitError] = useState<boolean>(false);
    const handleSubmit = () => {};
    const [openToModal, setOpenToModal] = useState(false);
    const openToModalToggle = () => {
        setOpenToModal(!openToModal);
    };

    const [openFromModal, setOpenFromModal] = useState(false);
    const [images, setImages] = useState([] as any);
    const [imageMaxLength, setImageMaxLength] = useState(false);
  const imageURLS = useState([]);

    const onImageChange = (e: any) => {
        let totalfiles = e.target.files.length;
        if (totalfiles < 1 ) return;
        else if(totalfiles > 10){
          setImages([]);
          setImageMaxLength(true);
          e.target.value= '';
          return;
        }
        else if(totalfiles >0 && totalfiles <=10){
          setImages([...e.target.files]);
        }
       
        images.forEach((image:any) => {
          if(imageURLS.length < 10){
            imageURLS.push(URL.createObjectURL(image))
          }
        });  
        
      }
    const openFromModalToggle = () => setOpenFromModal(!openFromModal);
    const cityOptions = [
        {
            label: " Free for Commercial and  Non-profit use",
            value: "Free for Commercial and  Non-profit use",
        },
        {
            label: "Free for Non-Profit use only",
            value: "Free for Non-Profit use only",
        },
    ];
    return (
        <Container fluid>
            <Row className="px-2">
                <Col sm="12">
                    <Card>
                        <CardBody className="add-post">
                            <Formik
                                initialValues={createPostInitialValue}
                                onSubmit={handleSubmit}
                                validationSchema={createPostSchema}>
                                {({ errors, values, setFieldValue }) => (
                                    <>
                                        <Form>
                                            <Row>
                                                <Col sm="12">
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Post Type`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <div className="px-2">
                                                            {/* <div className="m-checkbox-inline  py-1">
                                                            <Label for={`edo-ani-c`} className={`${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>
                                                                <Input
                                                                className="radio_animated"
                                                                id={`edo-ani-c`}
                                                                type="radio"
                                                                name="posttype"
                                                                onChange={()=>setPostType(1)}
                                                                />
                                                                <Field className="form-check-input" id={`edo-ani-c`} type="radio" name="posttype" value={values.posttype} onClick={()=>setPostType(1)}/>
                                                                {`Customer’s Wish of a Product or a Service`}
                                                            </Label>
                                                        </div>
                                                        <div className="m-checkbox-inline py-1">
                                                            <Label for={`edo-ani-d`} className={`ms-2${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>
                                                                <Input
                                                                className="radio_animated"
                                                                id={`edo-ani-d`}
                                                                type="radio"
                                                                name="posttype"
                                                                onChange={()=>setPostType(2)}
                                                                />
                                                                <Field className="form-check-input" id={`edo-ani-d`} type="radio" name="posttype" value={values.posttype} onClick={()=>setPostType(2)}/>
                                                                {`Job Opening: you want to hire`}
                                                            </Label>
                                                        </div>
                                                        <div className="m-checkbox-inline py-1">
                                                            <Label for={`edo-ani-e`} className={`${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>
                                                                <Input
                                                                className="radio_animated"
                                                                id={`edo-ani-e`}
                                                                type="radio"
                                                                name="posttype"
                                                                onChange={()=>setPostType(3)}
                                                                />
                                                                <Field className="form-check-input" id={`edo-ani-e`} type="radio" name="posttype" value={values.posttype} onChange={()=>setPostType(3)}/>
                                                                {`Notification Screen on My Media Center`}
                                                            </Label>
                                                        </div> */}

                                                            {/* <div className="radio-form py-1">
                                                            <FormGroup >
                                                                <Field className="form-check-input" id={`edo-ani-e`} type="radio" name="posttype" onClick={()=>setPostType(4)} />
                                                                <Label for={`edo-ani-e`} className={`ms-2 ${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>{`Article or Blog`}</Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="radio-form py-1">
                                                            <FormGroup >
                                                                <Field className="form-check-input" id={`edo-ani-c`} type="radio" name="posttype" onClick={()=>setPostType(1)} />
                                                                <Label for={`edo-ani-c`} className={`ms-2 ${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>{`Customer’s Wish of a Product or a Service`}</Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="radio-form py-1">
                                                            <FormGroup >
                                                                <Field className="form-check-input" id={`edo-ani-d`} type="radio" name="posttype" onClick={()=>setPostType(2)} />
                                                                <Label for={`edo-ani-d`} className={`ms-2 ${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>{`Job Opening: you want to hire`}</Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="radio-form py-1">
                                                            <FormGroup >
                                                                <Field className="form-check-input" id={`edo-ani-e`} type="radio" name="posttype" onClick={()=>setPostType(3)} />
                                                                <Label for={`edo-ani-e`} className={`ms-2 ${submitErrors && ` ${errors.posttype ? "text-danger" : "text-success"}`}`} check>{`Notification Screen on My Media Center`}</Label>
                                                            </FormGroup>
                                                        </div> */}

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_upload"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            1
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        1
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_upload">
                                                                    <H5>
                                                                        Article
                                                                        or Blog
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadc"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            2
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        2
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadc">
                                                                    <H5>
                                                                        Customer’s
                                                                        Wish of
                                                                        a
                                                                        Product
                                                                        or a
                                                                        Service
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadj"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            3
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        3
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadj">
                                                                    <H5>
                                                                        Job
                                                                        Opening:
                                                                        you want
                                                                        to hire
                                                                    </H5>
                                                                </Label>
                                                            </div>

                                                            <div className="form-check form-check-inline radio radio-primary mt-2 px-2">
                                                                <Input
                                                                    id="radioinline_uploadn"
                                                                    type="radio"
                                                                    name="posttype"
                                                                    onClick={() =>
                                                                        setPostType(
                                                                            4
                                                                        )
                                                                    }
                                                                    checked={
                                                                        postType ===
                                                                        4
                                                                    }
                                                                />
                                                                <Label
                                                                    className="mb-0"
                                                                    for="radioinline_uploadn">
                                                                    <H5>
                                                                        Notification
                                                                        Screen
                                                                        on My
                                                                        Media
                                                                        Center
                                                                    </H5>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label check>
                                                            {`Subject`}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <Field
                                                            type="text"
                                                            name="subject"
                                                            placeholder={`Subject`}
                                                            className={`form-control ${
                                                                submitErrors &&
                                                                `${
                                                                    errors.subject
                                                                        ? "is-invalid"
                                                                        : "is-valid"
                                                                }`
                                                            }`}
                                                        />
                                                        <TextCounter
                                                            length={
                                                                values?.subject
                                                                    ?.length
                                                            }
                                                            totalLength="140"
                                                        />
                                                        <ErrorMessage
                                                            name="subject"
                                                            component="span"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>
                                                   
          <Label check>Cover Image</Label>
          <FormGroup> 
            <Field
              className={`form-control ${
                submitErrors && `${errors.image ? "is-invalid" : "is-valid"}`
              }`}
              name="image"
              type="file"
              multiple
              accept="image/*"
              onChange={onImageChange}
            />
           {images.length > 10 ? (<span className='text-danger'>Max limit 10</span>):("")}
            <ErrorMessage
              name="image"
              component="span"
              className="invalid-feedback"
            />
            <ul>
            Total Image : {images.length}
            
            </ul>
          </FormGroup>
        
                                                    {/* <FormGroup>
                                                        <Label check>
                                                            Image
                                                        </Label>
                                                        <Field
                                                            name="uploadimage"
                                                            className="form-control"
                                                            type="file"
                                                            onChange={
                                                                uploadFileHandler
                                                            }
                                                        />
                                                        <span className="help-text text-muted">
                                                            Image dimension
                                                            160x117
                                                        </span>
                                                    </FormGroup> */}
                                                    {/* <RadioTypeForm /> */}
                                                    {postType === 1 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Field
                                                                        className={`custom-select  `}
                                                                        name="CategorySelect"
                                                                        options={
                                                                            cityOptions
                                                                        }
                                                                        component={
                                                                            CustomSelect
                                                                        }
                                                                        placeholder="Category"
                                                                        isMulti={
                                                                            true
                                                                        }
                                                                    />
                                                                    {/* <Input type="select" >
                                                                <option>{`Select`}</option>
                                                                <option>Free for Commercial and Non-profit use</option>
                                                                <option>Free for Non-Profit use only</option>*/}
                                                                    {/* <option>View Only. No right to download or use. </option>
                                                                <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                    {/* </Input>  */}
                                                                    {/* <Label>{`Category `}</Label> */}
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <Col
                                                                sm="12"
                                                                md="12">
                                                                <FormGroup>
                                                                    <Label
                                                                        className="w-100"
                                                                        check>
                                                                        {
                                                                            PostContent
                                                                        }
                                                                        :
                                                                    </Label>
                                                                    <SimpleMdeReact
                                                                        id="editor_container"
                                                                        options={{
                                                                            spellChecker:
                                                                                false,
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    {postType === 2 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Input type="select">
                                                                        <option>{`Select`}</option>
                                                                        <option>
                                                                            Free
                                                                            for
                                                                            Commercial
                                                                            and
                                                                            Non-profit
                                                                            use
                                                                        </option>
                                                                        <option>
                                                                            Free
                                                                            for
                                                                            Non-Profit
                                                                            use
                                                                            only
                                                                        </option>
                                                                        {/* <option>View Only. No right to download or use. </option>
                                                            <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                    </Input>
                                                                    <Label>{`Category`}</Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Product or Service Name`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Input type="select">
                                                                        <option>{`Select`}</option>
                                                                        <option>
                                                                            Value
                                                                            - 1
                                                                        </option>
                                                                        <option>
                                                                            Value
                                                                            - 2
                                                                        </option>
                                                                        {/* <option>View Only. No right to download or use. </option>
                                                            <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                    </Input>
                                                                    <Label>{`Product & Service Name `}</Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Desired Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="service"
                                                                    placeholder="New Album Name"
                                                                    maxLength="140"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.desired_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Max Budget`}
                                                                    :
                                                                </Label>
                                                                <Field
                                                                    type="text"
                                                                    name="service"
                                                                    placeholder="New Album Name"
                                                                    maxLength="140"
                                                                    className={`form-control ${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.max_budget
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}
                                                    {postType === 3 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Category *`}
                                                                    :
                                                                </Label>
                                                                <FormGroup
                                                                    floating>
                                                                    <Input type="select">
                                                                        <option>{`Select`}</option>
                                                                        <option>
                                                                            Free
                                                                            for
                                                                            Commercial
                                                                            and
                                                                            Non-profit
                                                                            use
                                                                        </option>
                                                                        <option>
                                                                            Free
                                                                            for
                                                                            Non-Profit
                                                                            use
                                                                            only
                                                                        </option>
                                                                        {/* <option>View Only. No right to download or use. </option>
                                                                <option>Display only on shopping pages. Not for social media usages</option> */}
                                                                    </Input>
                                                                    <Label>{`Category `}</Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {postType === 4 && (
                                                        <>
                                                            <FormGroup>
                                                                <Label check>
                                                                    {`Target Countries`}
                                                                    :
                                                                </Label>
                                                                <Country
                                                                    name="country"
                                                                    placeholder="Select"
                                                                    className={`${
                                                                        submitErrors &&
                                                                        `${
                                                                            errors.country
                                                                                ? "is-invalid"
                                                                                : "is-valid"
                                                                        }`
                                                                    }`}
                                                                    isMulti={
                                                                        false
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name="country"
                                                                    component="span"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>
                                                            <div className="email-wrapper">
                                                                <div className="theme-form">
                                                                    <FormGroup>
                                                                        <Label
                                                                            className="w-100"
                                                                            check>
                                                                            {
                                                                                Description
                                                                            }{" "}
                                                                            <span className="txt-danger">
                                                                                *
                                                                            </span>
                                                                            :
                                                                        </Label>
                                                                        {/* <SimpleMdeReact id="editor_container" value={BlogPostText} options={{ autofocus: true, spellChecker: false }} /> */}
                                                                        <Field
                                                                            as="textarea"
                                                                            name="description"
                                                                            className={`form-control ${
                                                                                submitErrors &&
                                                                                `${
                                                                                    errors.description
                                                                                        ? "is-invalid"
                                                                                        : "is-valid"
                                                                                }`
                                                                            }`}
                                                                            maxLength={
                                                                                "300"
                                                                            }
                                                                            placeholder="Description/Summary"
                                                                        />
                                                                        <TextCounter
                                                                            length={
                                                                                values
                                                                                    ?.description
                                                                                    ?.length
                                                                            }
                                                                            totalLength="300"
                                                                        />
                                                                        <ErrorMessage
                                                                            name="description"
                                                                            component="span"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    <FormGroup>
                                                        <div className=" col-form-Label">
                                                            {Keywords}:
                                                            <TagsInput
                                                                name="keywords"
                                                                placeholder="Add keywords"
                                                                className="w-100"
                                                                setFieldValue={
                                                                    setFieldValue
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label check>
                                                            {WhoCanSee}:
                                                        </Label>
                                                        <div className="m-checkbox-inline px-2">
                                                            <Label
                                                                for={`edo-ani-a`}
                                                                check>
                                                                <Input
                                                                    className="radio_animated"
                                                                    id={`edo-ani-a`}
                                                                    type="radio"
                                                                    name="rdo-ani"
                                                                />
                                                                {`Followers`}
                                                            </Label>
                                                        </div>
                                                        <div className="m-checkbox-inline px-2">
                                                            <Label
                                                                for={`edo-ani-b`}
                                                                check>
                                                                <Input
                                                                    className="radio_animated"
                                                                    id={`edo-ani-b`}
                                                                    type="radio"
                                                                    name="rdo-ani"
                                                                />
                                                                {`Any User`}
                                                            </Label>
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label check>
                                                            {InviteUsers}{" "}
                                                            <span className="txt-danger">
                                                                *
                                                            </span>
                                                            :
                                                        </Label>
                                                        <span
                                                            className="mt-1 btn btn-primary px-3 mx-2"
                                                            onClick={
                                                                openToModalToggle
                                                            }>
                                                            <i className="icofont icofont-user-alt-7 fa-sm"></i>
                                                        </span>
                                                        <span
                                                            className="mt-1 btn btn-primary px-3 mx-2"
                                                            onClick={
                                                                openFromModalToggle
                                                            }>
                                                            <i className="icofont icofont-users"></i>
                                                        </span>
                                                        <CommonModal
                                                            isOpen={openToModal}
                                                            toggle={
                                                                openToModalToggle
                                                            }
                                                            heading="Invite a Contact"
                                                            size="lg">
                                                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                                                <H3 className="modal-header justify-content-center border-0">
                                                                    Invite a
                                                                    Contact
                                                                </H3>
                                                                <InviteContactMain />
                                                            </div>
                                                        </CommonModal>
                                                        <CommonModal
                                                            isOpen={
                                                                openFromModal
                                                            }
                                                            toggle={
                                                                openFromModalToggle
                                                            }
                                                            heading="Add a Group"
                                                            size="lg">
                                                            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                                                                <H3 className="modal-header justify-content-center border-0">
                                                                    Add a Group
                                                                </H3>
                                                                <GroupMain />
                                                            </div>
                                                        </CommonModal>
                                                        <Typeahead
                                                            id="multiple-typeahead"
                                                            className="mt-2"
                                                            labelKey="name"
                                                            multiple
                                                            options={
                                                                postTypeData
                                                            }
                                                            placeholder={`Contact`}
                                                            autoFocus={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div className="btn-showcase">
                                            <Btn
                                                color="primary"
                                                type="submit"
                                                onClick={() =>
                                                    setSubmitError(true)
                                                }>{`Save`}</Btn>
                                            {/* <Btn color="light" type="reset">
                                        {`Cancel`}
                                        </Btn> */}
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePostContainer;
