import { Label } from "reactstrap";
import "./SubCategory.css";
import { ErrorMessage, Field } from "formik";
import CustomSelect from "./CustomSelect";

const categoryOptions = [
  {
    label: "category 1",
    value: "1",
  },
  {
    label: "category 2",
    value: "2",
  },
  {
    label: "category 3",
    value: "3",
  },
];

const SubCategory = (props: any) => {
  const { className, isMulti } = props;

  return (
    <>
      <Label check>
        Category <span className="txt-danger">*</span>
      </Label>
      <Field
        className={`custom-select ${className || ""}`}
        name="subcategory"
        options={categoryOptions}
        component={CustomSelect}
        placeholder="Please select a Category"
        isMulti={isMulti || false}
      />
      <ErrorMessage
        name="subcategory"
        component="span"
        className="invalid-feedback"
      />
    </>
  );
};

export default SubCategory;
