// import { Container } from "reactstrap";
// import {Customerwish, Post } from "../../../../utils/Constant";
// import MediaData from "../../../../Component/App/MediaData/MediaData";
// import { Home } from "react-feather";
// import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
// import ProductGrid from "../../../../Component/Application/Ecommerce/Products/ProductGrid/ProductGrid";
// import ProductFeatures from "../../../../Component/Application/Ecommerce/Products/ProductFeatures/ProductFeatures";
// import DefaultFilter from "../../../../Component/Application/Ecommerce/Products/ProductFeatures/DefaultFilter";
// import DeliveryService from "../../../../Component/App/Marketplace/DeliveryService";

 

// const CustomerWishes = () => {
//   return (
//     <div className="page-body">
//       <Breadcrumbs mainTitle={Customerwish} parent={Post} />
  import { useEffect, useState } from "react"; 
import { Container } from "reactstrap";  
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { setProductItem } from "../../../../ReduxToolkit/Reducers/ProductSlice";
import {  Customerwish, Post } from "../../../../utils/Constant";
import DeliveryService from "../../../../Component/App/Marketplace/DeliveryService";
import DefaultFilter from "../../../../Component/Application/Ecommerce/Products/ProductFeatures/DefaultFilter";
import ProductFeatures from "../../../../Component/Application/Ecommerce/Products/ProductFeatures/ProductFeatures";
import ProductGrid from "../../../../Component/Application/Ecommerce/Products/ProductGrid/ProductGrid";
import { productsData } from "../../../../Data/Application/Ecommerce/Product";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";

export const productst= [
  { id:1, src:"faq/1.jpg", name:"", fillStar:true},
  { id:2, src:"faq/1.jpg", name:"", fillStar:true},
  { id:3, src:"faq/1.jpg", name:"", fillStar:true},
  { id:4, src:"faq/1.jpg", name:"", fillStar:true},
  { id:5, src:"faq/1.jpg", name:"", fillStar:true},
  { id:6, src:"faq/1.jpg", name:"", fillStar:true},
  { id:7, src:"faq/1.jpg", name:"", fillStar:true},
  { id:8, src:"faq/1.jpg", name:"", fillStar:true},
  { id:9, src:"faq/1.jpg", name:"", fillStar:true},
  { id:10, src:"faq/1.jpg", name:"", fillStar:true},    
];


const CustomerWishes = () => {
  const [sideBarOn, setSideBarOn] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setProductItem(productsData));
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs mainTitle={Customerwish} parent={Post} />
      <PostsubMenu/>
      <Container
        fluid
        className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`}
      >       
        <DeliveryService/>
        <DefaultFilter/>      
        <div className="product-grid">
          <ProductFeatures />
          <ProductGrid /> 
        </div>
      </Container>
    </div>
  );
};

export default CustomerWishes;

//     </div>
//   );
// };

// export default CustomerWishes;
