import {useState} from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs/Breadcrumbs'
import {  BranchesColumnData, supportTicketData } from '../../../../Data/Tools/BrandSupportTools/ButtonActions'
import {  BranchsAction,  ManageOnlineStore } from '../../../../Data/Tools/ManageContents/ManageContentButtons'
import ManageOnlineSubMenu from '../ManageOnlineSubMenu/ManageOnlineSubMenu'
import BranchSubMenu from './BranchSubMenu/BranchSubMenu'


const ManageBranches = () => {
    const [toggleDelete, setToggleDelete] = useState(false);
    const [data, setData] = useState(supportTicketData);    
    const handleRowSelected =() =>{}
    return (
    <div className='page-body'>
      <Breadcrumbs mainTitle={`Manage Branches (Manage Store)`} parent={`Manage Store`} />
              <CardHeader className='pb-0 pt-0 px-2'> 
                <div>
                <ManageOnlineSubMenu />  
                </div>
              </CardHeader>
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <>
                <div className=''>
                <BranchSubMenu sliced="9"/> 
                    </div> 
                    <div className="table-responsive pt-2">
                    <DataTable className='custom-scrollbar' columns={BranchesColumnData} data={data} striped={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelete} />
                </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ManageBranches