import GroupChatImages from "./GroupChatImages"; 
import { Image, P } from "../../../../../AbstractElements";
import { dynamicImage } from "../../../../../Service";
import { useAppSelector } from "../../../../../ReduxToolkit/Hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { X } from "react-feather";

const RightGroupChatHeader = ({ToogleContactList}) => {
  const {selectedUser} = useAppSelector((state)=> state.chat)
  const user = JSON.parse(selectedUser);
  return (
    <div className="right-sidebar-title align-items-center px-2">
      <FontAwesomeIcon  icon={faArrowLeft}  style={{fontSize:"15px",marginRight:"20px"}} className="d-md-none" onClick={ToogleContactList}/>
      <div className="common-space">
        {/* <GroupChatImages /> */}
        <div className="chat-time">
          <div className="active-profile">
            <Image className="img-fluid rounded-circle" src={dynamicImage(user? user.image  : "user/1.jpg")} alt="user"/>
            <div className="status bg-success" />
          </div>
          <div>
            <span className="f-w-500">{user ? user.name : 'Current user'}</span>
            <P>Online</P>
          </div>
        </div>
        <div className="d-flex  d-md-none search-close-icon" onClick={ToogleContactList}>
           <X />
        </div>
      </div>
    </div>
  );
};

export default RightGroupChatHeader;
