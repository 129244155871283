export const calenderInitialData = {
  calendarEvents: [
    {
      title: "Atlanta Monster",
      // start: new Date("2022-04-04 00:00"),
      id: "1001",
      start: getDate("YEAR-MONTH-18T12:00:00+00:00"),
      end: getDate("YEAR-MONTH-18T12:00:00+00:00"),
      backgroundColor: '#ff5733',   
        borderColor : '#ff8s333', 

    },
    {
      title: 'Event 4',
      start: '2024-09-15T16:00:00',
      backgroundColor: '#f3ff33',  
      borderColor: '#f3ff33',
    },
    {
      title: "Arey Monster",
      // start: new Date("2022-04-04 00:00"),
      id: "1001",
      start: getDate("YEAR-MONTH-18T12:00:00+00:00"),
      end: getDate("YEAR-MONTH-18T12:00:00+00:00"),
      backgroundColor: '#ff6600',   
        borderColor : '##ff6600', 

    },
    {
      title: "My Favorite Murder",
      // start: new Date("2022-04-05 00:00"),
      start: getDate("YEAR-MONTH-16T12:00:00+00:00"),
      end: getDate("YEAR-MONTH-19T12:00:00+00:00"),
      id: "1002", 
    },  
  ],
  
  events: [
    {
      backgroundColor: '#378006',  
      borderColor: '#378006', 
      title: "Appointments",
      id: "1",
      icon: "fa fa-birthday-cake", 
      start:  getDate("YEAR-MONTH-9T12:00:00+00:00"), 
      end:  getDate("YEAR-MONTH-16T12:00:00+00:00"), 
    
    },
    { className: "bg-secondary", title: "Class Rooms", id: "2", icon: "fa fa-user",start:  getDate("YEAR-MONTH-9T10:00:00+00:00"), 
      end:  getDate("YEAR-MONTH-16T11:00:00+00:00"), },
    { className: "bg-primary", title: "Events", id: "3", icon: "fa fa-plane" },
    {
      // eventColor: "#378006",
      title: "Work Shifts",
      id: "4",
      icon: "fa fa-file-text",
    },   
           
  ],
};
function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}