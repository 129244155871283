import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAppDispatch } from "../ReduxToolkit/Hooks";
import { setPreviousUrl } from "../ReduxToolkit/Reducers/commonInputSlice";
import { useEffect } from "react";

const SecureRoute = () => {

  const { pathname } = useLocation();
  const [cookies] = useCookies(["confirmUser"]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPreviousUrl(pathname));
  },[pathname, dispatch]);
  
  const login = JSON.parse(localStorage.getItem("userInfo")!)
    ? JSON.parse(localStorage.getItem("userInfo")!)
    : false;

  const confirmUser = cookies.confirmUser === true ? true : false;

  return login !== false && confirmUser === true ? (
    <Outlet />
  ) : login !== false ? (
    <Navigate to={`${process.env.PUBLIC_URL}/confirm-password`} />
  ) : (
    <Navigate to={`${process.env.PUBLIC_URL}/login/1`} />
  );
};

export default SecureRoute;
