import { Table } from "reactstrap";
import React from "react";

const StaticPlanData = (staticData: any, allregistrationplans: any) => {
  const planData = staticData.staticData.data.static;
  // console.log(JSON.stringify(staticData.staticData))
  return (
    <div className="border-bottom-0 auto text-nowrap px-4 p-5">
      <Table bordered hover>
        <tbody>
          <tr>
            <td>
              <b>{planData.plan_name}</b>
            </td>
          </tr>
          <tr style={{ height: "225px" }}>
            <td rowSpan={planData.pricings.length}>
              {Object.keys(planData.pricings).map((item, index) => (
                <p key={index}>{/*planData.pricings[item]*/}</p>
              ))}
            </td>
          </tr>
          {planData.details.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <b>{item.heading}</b>
                </td>
              </tr>
              <tr>
                <td
                  rowSpan={item.sub_headings.length}
                  className=""
                  style={{
                    WebkitLineClamp: 2,
                    whiteSpace: "break-spaces",
                    minHeight: "51px",
                  }}
                >
                  {Object.keys(item.sub_headings).map((sub_item, i_index) => (
                    <p key={i_index}>{item.sub_headings[sub_item]}</p>
                  ))}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StaticPlanData;
