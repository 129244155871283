import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import PostsubMenu from "../../../../Component/App/PostSubMenu/PostsubMenu";
 
import SurveysContainer from "../../../../Component/Application/Surveys/SurveysContainer";
import { Parent1Web } from "../../../../utils/Constant";

const Surveys = () => {
  return (
    <div className="page-body"> 
      <Breadcrumbs mainTitle={'Surveys'} parent={Parent1Web} />
      <PostsubMenu/> 
      <SurveysContainer/>

    </div>
  );
};

export default Surveys;
