import React,{useState} from 'react'
import CommonModal from '../Ui-Kits/Modal/Common/CommonModal'
import { H3 } from '../../AbstractElements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { X } from 'react-feather';

export interface PopupModal{
    children?: React.ReactNode;
    title:string;
    isOpen:boolean;
    size:string;
    backdrop:boolean;
    isClose:boolean;
    openModalToggle:() =>void;
} 

const Popup:React.FC<PopupModal> = ({title, isOpen, size,backdrop, isClose, openModalToggle, children}) => {
    console.log("=>"+(isOpen ?"yes":"no"));
    return (
        <CommonModal isOpen={isOpen}  toggle={openModalToggle} modalBodyClassName="p-0" size={size} backdrop={backdrop}>
            <div className="modal-toggle-wrapper social-profile text-start dark-sign-up">
                <div className="modal-header  ">
                    <H3 className="w-100 text-left"> {title}</H3>
                    <div
                onClick={openModalToggle}
                className="search-close-icon">
                <X />
              </div>
                    {isClose ?? <div className='w-10 justify-content-end' onClick={openModalToggle}>
                        {/* <FontAwesomeIcon icon={fas.faClose} size={`xl`} className='pe-2 pull-right'/> */}
                        </div>}
                </div>
                <div style={{height:"60vh",overflow:"auto"}}>
                {children}
                </div>
            </div>
        </CommonModal>
    )
}

export default Popup