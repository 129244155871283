import { Users } from 'react-feather';
import { useState } from 'react';
import { Btn } from '../../../../AbstractElements';
// import { useForm } from 'react-hook-form'
import { NewContacts, } from '../../../../utils/Constant';
// import { ContactUsersType } from '../../../../Types/Application/Contacts/Contacts';
import Popup from '../../../MasterPopup/Popup';
import FormPost from '../../../Miscellaneous/Blog/AddPost/FormPost';

const CreateContact = () => {
  const [openModal, setOpenModal] = useState(false);
  // const {formState: { errors },reset} = useForm<ContactUsersType>();
  // const dispatch = useAppDispatch();

  // const AddContact: SubmitHandler<ContactUsersType> = (data) => {
  //   if (data !== undefined) {
  //     dispatch(createUser(data));
  //     dispatch(setModal());
  //     reset();
  //   }
  // };
  return (
    <>
      <Btn className="badge-light-primary txt-primary btn-mail btn-dark" color="black" onClick={()=>setOpenModal(!openModal)}>
        <Users className="me-1" />{NewContacts}
      </Btn>
      {/* <Modal className="modal-bookmark" isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{AddContacts}</ModalHeader>
        <ModalBody>
          <Form className="form-bookmark needs-validation" onSubmit={handleSubmit(AddContact)}>
            <Row className="g-2">
              <Col md="12" className='mt-0 mb-3'>
                <Row>
                  <Label check>{Name}</Label>
                  <Col sm="6">
                    <input className={`form-control ${contactValidation && `${errors.name ? "is-invalid" : "is-valid"}`}`} type="text" {...register("name", { required: true })} placeholder={FirstName} />
                  </Col>
                  <Col sm="6">
                    <input className={`form-control ${contactValidation && `${errors.sureName ? "is-invalid" : "is-valid"}`}`} type="text" {...register("sureName", { required: true })} placeholder={LastName} />
                  </Col>
                </Row> 
              </Col>
              <Col md="12">
                <Label check>{EmailAddress}</Label>
                <input className={`form-control ${contactValidation && `${errors.email ? "is-invalid" : "is-valid"}`}`} type="text" {...register("email", { required: true })} />
              </Col>
              <Col md="12"> 
                <Label check>{Phone}</Label>
                <Row>
                  <Col sm="6">  
                    <input className={`form-control ${contactValidation && `${errors.mobile ? "is-invalid" : "is-valid"}`}`} type="number" {...register("mobile", { required: true })} />
                  </Col>
                  <Col sm="6">
                    <Input type="select">
                      <option>{'Mobile'}</option>
                      <option>{'Work'}</option>
                      <option>{'Other'}</option>
                    </Input>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Btn color="secondary" className='me-3' type="submit" onClick={() => dispatch(setContactValidation(true))}>{Save}</Btn>
            <Btn color="primary" onClick={toggle}>{Cancel}</Btn>
          </Form>
        </ModalBody>
      </Modal> */}
      <Popup title={`New Contact`} openModalToggle={()=>setOpenModal(!openModal)} isOpen={openModal} size={`xl`} backdrop={true} isClose={false}>
        <div className='px-4'>
        <FormPost/>
        </div>
      </Popup>
    </>
  )
}

export default CreateContact