import * as Yup from "yup";
import { FormikErrors } from "formik";

export interface CreatePostValidationProp {
    posttype:string;
    subject: string;
    desired_budget: string; 
    max_budget: string;
    country: string;
    description: string;
    users:string;
}

export const createPostInitialValue:CreatePostValidationProp ={
    posttype:"",
    subject:"",
    desired_budget: "", 
    max_budget:"",
    country:"",
    description:"",
    users:"",
}

export const createPostSchema = Yup.object().shape({
    posttype:Yup.string().required(), 
    subject: Yup.string().required("Required").min(5), 
    desired_budget: Yup.string().required("Required"), 
    max_budget: Yup.string().required("Required"), 
    country: Yup.string().required("Required"), 
    description: Yup.string().required("Required"), 
    users: Yup.string().required("Required"), 
});

export interface PostFormValidationsProp {
    submitErrors: boolean;
    setSubmitError: (key:boolean)=>void
    errors: FormikErrors<CreatePostValidationProp>;
    values:any;
  }