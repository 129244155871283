import { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { InputGroup } from "reactstrap";
import { Btn } from "../../AbstractElements";
import {
  TwoFactorAutheticationFormProps,
  twoFactorAuthenticationFormInitialValues,
  twoFactorAuthenticationFormSchema,
} from "./FormSchema";
import { useVerifyTwoFactorAutheticationMutation } from "../../ReduxToolkit/Reducers/Authentication/AuthApiSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../ReduxToolkit/Reducers/Authentication/authSlice";
import { AfterLoginRedirectUrl } from "../../utils/Constant";
import Loading from "../Loading/Loading";

function TwoFactorForm() {
  const [verifyTwoFactorAutheticationCall, { isLoading }] =
    useVerifyTwoFactorAutheticationMutation();

  const [submitErrors, setSubmitError] = useState<boolean>(false); 

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const redirect = AfterLoginRedirectUrl;

  const handleTwoFactorAuthentication = async (
    values: TwoFactorAutheticationFormProps
  ) => {
     
    try {
      if (values.code) {
        const response = await verifyTwoFactorAutheticationCall({
          code: values.code,
        }).unwrap();  
        const { success, message, data } : any = response; 
        if (success === true) { 
          dispatch(setCredentials({ token : data?.token}));
          dispatch(setCredentials({...data}));
          toast.success(message);
          navigate(redirect);
        }else{
          toast.error(message);
        }
      }
    } catch (error: any) {
      toast.error(error?.data?.message || "Something went wrong.");
    }
  };

  if(isLoading) return <Loading/>;
  
  return (
    <Formik
      initialValues={twoFactorAuthenticationFormInitialValues}
      onSubmit={handleTwoFactorAuthentication}
      validationSchema={twoFactorAuthenticationFormSchema}
    >
      {({ errors, values, setFieldValue }) => (
        <Form>
          <InputGroup className="mb-3">
            <Field
              type="text"
              name="code"
              className={`form-control ${
                submitErrors && `${errors.code ? "is-invalid" : "is-valid"}`
              }`}
              placeholder="Please enter the code here"
              autoFocus={true}
            />
            <ErrorMessage
              name="code"
              component="span"
              className="invalid-feedback"
            />
            <Btn
              color="primary"
              type="submit"
              onClick={() => setSubmitError(true)}
            >
              Verify
            </Btn>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}

export default TwoFactorForm;
